import * as React from "react";
import { useAuthenticated } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';
import { Title, Button } from 'react-admin';
import LogoutIcon from '@mui/icons-material/Logout';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import { apiUrl } from "../api/MyDataProvider";

const GetTokens = () => {
    useAuthenticated(); // redirects to login if not authenticated
    
    return (
    <Card sx={{textAlign: "center"}}>
        <h1>Tokens para acesso as APIs das plataformas</h1>
        <Title title="Tokens" />
        <CardContent>
            <Box>
                <Button label="Facebook Ads Tokens" href={`${apiUrl}/tokens/fblogin`}
                    variant="contained" startIcon={<FacebookIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout Facebook Ads" href={`${apiUrl}/tokens/logoutfb`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
            <Box mt="1rem">
                <Button label="GA4 (Analytics) Tokens" href={`${apiUrl}/tokens/ga4login`}
                    variant="contained"  startIcon={<GoogleIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout GA4 Analytics" href={`${apiUrl}/tokens/logoutga4`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
            <Box mt="1rem">
                <Button label="Google Ads Tokens" href={`${apiUrl}/tokens/googleadslogin`}
                    variant="contained"  startIcon={<GoogleIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout Google Ads" href={`${apiUrl}/tokens/logoutgads`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
            <Box mt="1rem">
                <Button label="Pinterest Ads Tokens" href={`${apiUrl}/tokens/pinterestadslogin`}
                    variant="contained"  startIcon={<PinterestIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout Pinterest Ads" href={`${apiUrl}/tokens/logoutpinads`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
            <Box mt="1rem">
                <Button label="LinkedIn Ads Tokens" href={`${apiUrl}/tokens/lnkdlogin`}
                    variant="contained"  startIcon={<LinkedInIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout LinkedIn Ads" href={`${apiUrl}/tokens/logoutlnkd`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
            <Box mt="1rem">
                <Button label="TikTok Ads Tokens" href={`${apiUrl}/tokens/tiktoklogin`}
                    variant="contained"  startIcon={<SlowMotionVideoIcon />}
                    sx={{
                        marginRight: "2rem"
                    }} />
                <Button label="Logout TikTok Ads" href={`${apiUrl}/tokens/logouttk`}
                    variant="contained" startIcon={<LogoutIcon />} />
            </Box>
        </CardContent>
    </Card>
    )
};

export default GetTokens;
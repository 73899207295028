import { Datagrid, List, TextField, SimpleList, EditButton, ShowButton, CloneButton } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ClientListActions } from "../../toolbars/ClientListActions"
import { Filters } from "../../toolbars/ClientFilters"
import { ListPagination } from '../../other_components/ListPagination';

export const ClientsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List 
        actions={<ClientListActions/>}
        filters={Filters} filterDefaultValues={{ active: true }}
        pagination={<ListPagination />} perPage={20}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => record.client_name}
                    secondaryText={record => `id no WP: ${record.wp_id}`}
                    tertiaryText={record => `Tipo: ${record.ga_metrics_type}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="client_name" />
                    <TextField source="wp_id" />
                    <TextField source="wp_slug" />
                    {/* <UrlField source="data_studio_url" /> */}
                    <TextField source="ga_metrics_type" />
                    {/* <TextField source="group_type_list" />
                    <TextField source="custom_conversion_names.1022857061974798" />
                    <TextField source="ads_platforms.google_ads" />             */}
                    <EditButton label="" />
                    <ShowButton  label="" />
                    <CloneButton label="" />
                </Datagrid>
            )
            }
        </List>
    )
}

export default ClientsList;
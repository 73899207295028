import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

interface Props {    
    source?: any;
    preText: string;
}

const PageTitle = (props: Props) => {
    const { source, preText } = props;
    const record = useRecordContext();
    
    // the record can be empty while loading
    // if (!record) return null;
    return record ? <span>{preText}{get(record, source)}</span> : null;
};

export default PageTitle;
import React from 'react';
import { useNotify, useRecordContext, useDataProvider } from 'react-admin';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { useMutation } from 'react-query';
import SendIcon from '@mui/icons-material/Send';

export const SendReportButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const notify = useNotify();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { mutate, isLoading } = useMutation(
        ['send_messages/send_report'],
        () => dataProvider.sendSimplified(`send_messages/send_report`, {            
            data: {
                "record_id": record.id
            }
        }),        
        { onSuccess: () => notify('ra.notification.send_email', { type: 'success' }) },
        {
            onFailure: (error) => notify(`Erro no envio: ${error.message}`, { type: 'error' }),
        }
    );

    return (
        <>
            {isSmall ? (
                <Button
                    label="Enviar relatório"
                    children="Enviar"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="outlined"
                    color="primary"
                    startIcon={<SendIcon />}
                />
            ) : (
                <Button
                    label="Enviar relatório"
                    children="Enviar relatório"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="outlined"
                    color="primary"
                    startIcon={<SendIcon />}
                />
            )}
        </>
    )
}
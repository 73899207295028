const ptBrMessages = {
  resources: {
    clients: {
      name: 'Cliente |||| Clientes',
      nameplural: "Clientes",
      annotation: "Anotação",
      event_date: "Data do evento",
      fields: {
        _id: 'ID',
        id: 'ID',
        client_name: 'Nome do cliente',
        phones: 'Números do WhatsApp',
        description: 'Descrição',
        active: 'Ativo',
        email: 'e-mail',
        created_at: 'Criado em',
        updated_at: "Atualizado em",
        wp_id: "ID WordPress",
        wp_slug: "Slug WordPress",
        ga_metrics_type: "Tipo de métricas no Analytics",
        data_studio_url: "URL do Data Studio",
        group_type_list: "Agrupamentos para relatórios",
        custom_conversion_names: "Conversões customizadas",
        "custom_conversion_names.name": "Nome da conversão",
        "custom_conversion_names.conversion_id": "ID da conversão",
        "ads_platforms.google_ads": "ID Google Ads",
        "ads_platforms.google_analytics": "ID Google Analytics",
        "ads_platforms.ga4": "ID GA4",
        "ads_platforms.facebook_ads": "ID Facebook Ads",
        "ads_platforms.pinterest_ads": "ID Pinterest Ads",
        "ads_platforms.tiktok_ads": "ID TikTok Ads",
        "ads_platforms.linkedin_ads": "ID LinkedIn Ads",
        "weekly_report_schedule.weekly_report_schedule": "Enviar relatório semanal",
        "weekly_report_schedule.send_to_email": "Enviar por email",
        "weekly_report_schedule.send_to_whatsapp": "Enviar por WhatsApp",
        "weekly_report_schedule.text_resume_template_id": "Template do relatório",
        only_custom_conversions_on_fb: "Só conv. custom no FB",
      },
    },

    reports: {
      name: 'Relatório |||| Relatórios',
      nameplural: "Relatórios",
      fields: {
        _id: 'ID',
        id: 'ID',
        "client_config.name": 'Cliente',
        current_period_start: 'Início',
        current_period_end: 'Até',
        previous_period_start: 'Início período anterior',
        previous_period_end: 'Fim período anterior',
        "payload.title": 'Título',
        report_wp_id: 'ID no WordPress',
        report_wp_url: 'URL do relatório',
        created_at: 'Criado em',
        updated_at: 'Atualizado em',
        only_test: 'Somente teste',
        update_elementor: 'Atualizar template do Elementor',
        include_fb_custom_conversions_in_total: 'Incluir conv. personalizadas do FB?',
        include_pinads_in_total: 'Incluir resultados do Pinterest Ads no total?',
        include_tkads_in_total: 'Incluir resultados do TikTok Ads no total?',
        "payload.titulo": "Título",
        "payload.valor_total_impressoes": "Impressões",
        "payload.valor_total_interacoes": "Interações",
        "payload.valor_total_conversoes": "Conversões",
        "payload.valor_total_investimento": "Investimento",

        "payload.tooltip_card_1": "Tooltip Card Impressões",
        "payload.tooltip_card_2": "Tooltip Card Interações",
        "payload.tooltip_card_3": "Tooltip Card Investimento",
        "payload.tooltip_card_4": "Tooltip Card Conversões",

        "payload.perc_total_google_ads": "% Invest GAds",
        "payload.perc_total_facebook_ads": "% Invest FBAds",
        "payload.perc_mobile_google_ads": "% Invest Mobile GAds",
        "payload.perc_busca_google_ads": "% Invest busca GAds",

        "payload.valor_atual_chart_google_1": "Atual - Interações/Investimento",
        "payload.valor_anterior_chart_google_1": "Anterior - Interações/Investimento",
        "payload.valor_atual_chart_google_2": "Atual - Impressões",
        "payload.valor_anterior_chart_google_2": "Anterior - Impressões",
        "payload.valor_atual_chart_google_3": "Atual - Conversões",
        "payload.valor_anterior_chart_google_3": "Anterior - Conversões",

        "payload.valor_atual_chart_facebook_1": "Atual - Interações/Investimento",
        "payload.valor_anterior_chart_facebook_1": "Anterior - Interações/Investimento",
        "payload.valor_atual_chart_facebook_2": "Atual - Impressões",
        "payload.valor_anterior_chart_facebook_2": "Anterior - Impressões",
        "payload.valor_atual_chart_facebook_3": "Atual - Conversões",
        "payload.valor_anterior_chart_facebook_3": "Anterior - Conversões",

        "payload.celula1": "Impressões",
        "payload.celula2": "Interações",
        "payload.celula3": "CPC",
        "payload.celula4": "Investimento",
        "payload.celula5": "Conversões",
        "payload.celula6": "Taxa de Conversão",
        "payload.celula7": "Custo por Conversão",
        "payload.celula8": "Impressões",
        "payload.celula9": "Interações",
        "payload.celula10": "CPC",
        "payload.celula11": "Investimento",
        "payload.celula12": "Conversões",
        "payload.celula13": "Taxa de Conversão",
        "payload.celula14": "Custo por Conversão",

        "client_config._id": 'ID',
        "client_config.id": 'ID',
        "client_config.client_name": 'Cliente',
        "client_config.description": 'Descrição',
        "client_config.active": 'Ativo',
        "client_config.email": 'e-mail',
        "client_config.created_at": 'Criado em',
        "client_config.updated_at": "Atualizado em",
        "client_config.wp_id": "ID WordPress",
        "client_config.wp_slug": "Slug WordPress",
        "client_config.ga_metrics_type": "Tipo de métricas no Analytics",
        "client_config.data_studio_url": "URL do Data Studio",
        "client_config.group_type_list": "Agrupamentos para relatórios",
        "client_config.custom_conversion_names": "Conversões customizadas",
        "client_config.custom_conversion_names.name": "Nome da conversão",
        "custom_conversion_names.conversion_id": "ID da conversão",
        "client_config.ads_platforms.google_ads": "ID Google Ads",
        "client_config.ads_platforms.google_analytics": "ID Google Analytics",
        "client_config.ads_platforms.facebook_ads": "ID Facebook Ads",
        "client_config.only_custom_conversions_on_fb": "Só conv. custom no FB",

        "client_id": "Cliente",
        "report_model_id": "Elementor",
        "text_resume_template_id": "Texto resumo",
        "text_gads_template_id": "Texto Google Ads",
        "text_fbads_template_id": "Texto FB Ads",
        "text_other_template_id": "Texto outras plataformas",
        "text_metrics_template_id": "Texto com métricas",
        "send_report_date": "Data de envio do relatório",
        "report_sent": "Enviado?",
      },
    },

    reports_simplified: {
      name: 'Relatório simplificado |||| Relatórios simplificados',
      nameplural: "Relatórios simplificados",
      fields: {
        _id: 'ID',
        id: 'ID',
        "client_config.client_name": "Cliente",
        created_at: 'Criado em',
        current_period_start: 'Início',
        current_period_end: 'Fim',
        previous_period_start: 'Início período anterior',
        previous_period_end: 'Fim período anterior',
        text_resume: 'Texto',
        send_to_whatsapp: "Enviar por WhatsApp",
        send_to_email: "Enviar por email",
        include_fb_custom_conversions_in_total: 'Conversões FB',
        include_pinads_in_total: 'Pinterest Ads',
        include_tkads_in_total: 'TikTok Ads',
      },
    },

    text_templates: {
      name: 'Template de Texto |||| Templates de Texto',
      nameplural: "Templates de Texto",
      fields: {
        _id: 'ID',
        id: 'ID',
        name: 'Nome',
        report_type: 'Tipo',
        content: 'Conteúdo',
        "client._id": 'Cliente',
        "client.id": 'Cliente',
        "client.client_name": "Nome do cliente",
        updated_at: 'Atualizado em',
      },
    },

    elementor_templates: {
      name: 'Template do Elementor |||| Templates do Elementor',
      nameplural: "Templates do Elementor ",
      fields: {
        _id: 'ID',
        id: 'ID',
        name: 'Nome',
        _elementor_data: '_elementor_data',
        _elementor_controls_usage: '_elementor_controls_usage',
        _edit_last: '_edit_last',
        _wp_page_template: '_wp_page_template',
        _edit_lock: '_edit_lock',
        _elementor_edit_mode: '_elementor_edit_mode',
        _elementor_template_type: '_elementor_template_type',
        _elementor_version: '_elementor_version',
        wp_post_id: 'ID no Wordpress'
      },
    },
  },
  ra: {
    action: {
      add_filter: 'Adicionar filtro',
      add: 'Adicionar',
      back: 'Retornar',
      bulk_actions:
        '%{smart_count} selecionado |||| %{smart_count} selecionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpar valor',
      clone: 'Duplicar',
      confirm: 'Confirmar',
      create: 'Criar',
      delete: 'Remover',
      edit: 'Alterar',
      export: 'Exportar',
      list: 'Listar',
      refresh: 'Atualizar',
      remove_filter: 'Remover filtro',
      remove: 'Remover',
      save: 'Salvar',
      search: 'Buscar',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Desfazer',
      expand: 'Expandir',
      close: 'Fechar',
      open_menu: 'Abrir menu',
      close_menu: 'Fechar menu',
      unselect: 'Desmarcar',
      save_and_show: 'Salvar e mostrar',
      save_and_go_to_list: 'Salvar',
      send_credit_email: "E-mail de crédito",
      send: "Enviar",
      update: "Atualizar",
      send_simplified: "Enviar simplificado",
      simplified_report: "Relatório Simplificado",
      add_annotation: "Adicionar",
      del_annotation: "Deletar",
      clear_array_input: "Deletar entrada",
      change_date: "Alterar data de envio"
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
      null: '',
    },
    page: {
      create: 'Criar %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Ocorreu um problema',
      list: '%{name}',
      loading: 'Carregando',
      not_found: 'Não encontrado',
      show: '%{name} #%{id}',
      empty: 'Sem %{name} ainda.',
      invite: 'Você quer adicionar um?',
    },
    input: {
      file: {
        upload_several:
          'Solte arquivos para upload, ou clique para selecionar um.',
        upload_single:
          'Solte um arquivo para upload, ou clique para selecioná-lo.',
      },
      image: {
        upload_several:
          'Solte imagens para upload, ou clique para selecionar uma.',
        upload_single:
          'Solte uma imagem para upload, ou clique para selecioná-la.',
      },
      references: {
        all_missing:
          'Não foi possível encontrar os dados de referência.',
        many_missing:
          'Pelo menos uma das referências associadas aparenta não estar mais disponível.',
        single_missing:
          'A referência associada aparenta não estar mais disponível.',
      },
      password: {
        toggle_visible: 'Ocultar senha',
        toggle_hidden: 'Mostrar senha',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Tem certeza?',
      bulk_delete_content:
        'Você tem certeza que quer remover isso %{name}? |||| Você tem certeza que quer remover estes %{smart_count} items?',
      bulk_delete_title:
        'Remover %{name} |||| Remover %{smart_count} %{name}',
      delete_content: 'Você tem certeza que quer remover este item?',
      delete_title: 'Remover %{name} #%{id}',
      details: 'Detalhes',
      error:
        'Ocorreu um erro no navegador e seu requisição não foi completada.',
      invalid_form:
        'O formulário não é válido. Por favor, verifique os erros',
      loading: 'A página está carregando, aguarde um momento',
      no: 'Não',
      not_found: 'Você digitou uma URL errada, ou seguiu um link errado.',
      yes: 'Sim',
      unsaved_changes:
        'Algumas de suas alteracões não foram salvas. Você tem certeza que quer ignorá-las?',
      clear_array_input: "Entrada deletada",
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results:
        'A página número %{page} fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'A página %{page} está fora dos limites',
      page_out_from_end: 'Fim da paginação',
      page_out_from_begin: 'A página não pode ser menor que 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Linhas por página:',
      next: 'Próxima',
      prev: 'Anterior',
      skip_nav: 'Ir para o conteúdo',
    },
    sort: {
      sort_by: 'Ordernar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente',
    },
    auth: {
      auth_check_error: 'Faça o login para continuar',
      user_menu: 'Perfil',
      username: 'Usuário',
      password: 'Senha',
      sign_in: 'Entrar',
      sign_in_error: 'Erro na autenticação, por favor tente novamente',
      "Error: LOGIN_BAD_CREDENTIALS": 'Erro na autenticação de usuário ou senha',
      logout: 'Sair',
      google_login: 'Google Login'
    },
    notification: {
      updated:
        'Elemento atualizado |||| %{smart_count} elementos atualizados',
      created: 'Elemento criado',
      deleted:
        'Elemento removido |||| %{smart_count} elementos removidos',
      bad_item: 'Elemento incorreto',
      item_doesnt_exist: 'O elemento não existe',
      http_error: 'Erro de comunicação com o servidor',
      data_provider_error:
        'Erro com o dataProvider. Verifique o console para mais detalhes.',
      i18n_error:
        'Não foi possível carregar as traduções para a linguagem especificada',
      canceled: 'Ação cancelada',
      logged_out: 'Sua sessão foi finalizada, por favor reconecte.',
      send_email: 'e-mail enviado com sucesso!',
      create_update_user: 'Usuário/contato criar/atualizado com sucesso',
      needLogin: 'Login necessário para acessar',
      send_simplified: 'Mensagem enviada com sucesso!',
      add_annotation: 'Evento adicionado com sucesso',
      del_annotation: 'Evento deletado com sucesso',
      server_side_error: 'Erro: %{smart_count}',
      change_date: 'Data alterada com sucesso',
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve ter pelo menos %{min} caracteres',
      maxLength: 'Deve ter no máximo %{max} caracteres',
      minValue: 'Deve ser no mínimo %{min}',
      maxValue: 'Deve ser no máximo %{max}',
      number: 'Deve ser um número',
      email: 'Deve ser um e-mail válido',
      oneOf: 'Deve ser um destes: %{options}',
      regex: 'Deve respeitar um formato específico (regexp): %{pattern}',
      nameRequired: 'O nome é obrigatório',
      industryRequired: 'O Ramo/Setor é obrigatório',
      jobPayTypeRequired: 'O tipo de pagamento é obrigatório',
      jobStatusRequired: 'O status do trabalho é obrigatório',
      jobPayAccountRequired: 'A conta de pagamento do trabalho é obrigatória',
      end_date_error: "Deve ser maior que a data inicial do período",
      previous_end_date_error: "Deve ser menor que a data final do período atual",
      generic_date_error: "Corrija o erro referente as datas",
      duplicated_annotation: "Data duplicada. Já existe evento com essa data.",
    },
    helperText: {
      wp_id: 'ID do WordPress para update',
      template_text: "Selecione o template"
    },
    configurable: {
      customize: 'Customizável',
    },
  },
};

export default ptBrMessages
import * as React from "react";
import {
    TextInput, Create, SimpleForm
    // number, minValue, maxLength
} from 'react-admin';
// import { RichTextInput } from 'ra-input-rich-text';
import EditCreateToolbar from "../../toolbars/EditCreateToolbar";
import PageTitle from '../../other_components/PageTitle.tsx';
import { Box, Divider, Tooltip } from '@mui/material';

// const validateMinNumbers = [number(), minValue(0)]
// const validateLength = [maxLength(12)]

export const ElementorCreate = () => (
    <Create
        title={<PageTitle preText="Editando Elementor: " source="name" />} >
        <SimpleForm variant="standard"
            toolbar={<EditCreateToolbar />}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: { xs: '100%', sm: '100%', lg: '60%', xl: '50%' }
            }} >
                <Box flex={3} mr='2rem' sx={{ minWidth: "10rem" }}>
                    <TextInput source="name" fullWidth />
                </Box>
                <Tooltip title="Caso preenchido, buscará template no WordPress" arrow>
                <Box flex={1} mr='2rem' sx={{ minWidth: "3rem" }}>
                    <TextInput source="wp_post_id" fullWidth />
                </Box>
                </Tooltip>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: { xs: '100%', sm: '100%', lg: '60%', xl: '50%' }
            }} >
                <Box flex={1} mr='2rem'>
                    <TextInput source="_edit_last" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
                <Box flex={1} mr='2rem'>
                    <TextInput source="_wp_page_template" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
                <Box flex={1} mr='2rem'>
                    <TextInput source="_edit_lock" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: { xs: '100%', sm: '100%', lg: '60%', xl: '50%' }
            }} >
                <Box flex={1} mr='2rem'>
                    <TextInput source="_elementor_edit_mode" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
                <Box flex={1} mr='2rem'>
                    <TextInput source="_elementor_template_type" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
                <Box flex={1} mr='2rem'>
                    <TextInput source="_elementor_version" fullWidth sx={{ minWidth: "6rem" }} />
                </Box>
            </Box>
            <Divider flexItem />
            <TextInput source="_elementor_data" isRequired fullWidth multiline />
            <Divider flexItem />
            <TextInput source="_elementor_controls_usage" isRequired fullWidth multiline />
        </SimpleForm >
    </Create>
);

import {
    TextField, Show, SimpleShowLayout, Labeled
} from 'react-admin';
import PageTitle from '../../other_components/PageTitle.tsx';
import { ShowActions } from "../../toolbars/ShowActions"

import { Box, Divider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';


export const ElementorShow = () => {
    const theme = useTheme()

    return (

        <Show title={<PageTitle preText="Template Elementor: " source="name" />}
            actions={<ShowActions />} >
            <SimpleShowLayout >
                <Box flex={1} sx={{ display: "flex", flexDirection: 'row', marginRight: '1rem' }}>
                    <Box mr='2rem' >
                        <Labeled><TextField source="name" /></Labeled>                        
                    </Box>
                    <Box mb='1rem' >                        
                        <Labeled><TextField source="wp_post_id" /></Labeled>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    padding: '1rem',
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '1rem',
                }} >
                    <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><TextField source="_wp_page_template" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><TextField source="_edit_lock" /></Labeled>
                        </Box>
                    </Box>
                    <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><TextField source="_elementor_edit_mode" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><TextField source="_elementor_template_type" /></Labeled>
                        </Box>
                    </Box>
                    <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><TextField source="_elementor_template_type" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><TextField source="_edit_last" /></Labeled>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Labeled>
                        <TextField source="_elementor_data"
                            sx={{
                                wordBreak: "break-all"
                            }}
                        />
                    </Labeled>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Labeled>
                        <TextField source="_elementor_controls_usage"
                            sx={{
                                wordBreak: "break-all"
                            }}
                        />
                    </Labeled>
                </Box>
            </SimpleShowLayout>
        </Show >
    )
}

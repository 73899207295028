import * as React from "react";
import {
    Create, SimpleForm, DateInput, ReferenceInput, AutocompleteInput, BooleanInput,
    FormDataConsumer, required, useCreate, useRedirect, useNotify
} from 'react-admin';
import { useCallback } from 'react';
import EditCreateToolbar from "../../toolbars/EditCreateToolbar";
import { EditActions } from "../../toolbars/EditActions"
import { Box } from '@mui/material';
import {
    GetFirstDayOfLastWeek, GetLastDayOfLastWeek, GetFirstDayOfTwoWeeksAgo, GetLastDayOfTwoWeeksAgo
} from '../../other_components/Dates'
import { validateSimplifiedReport } from '../validateSimplifiedReport'



export const ReportsSimplifiedCreate = () => {

    const redirect = useRedirect();
    const notify = useNotify();

    const [create] = useCreate();
    const save = useCallback(
        async values => {
            try {
                await create(
                    'reports_simplified',
                    { data: values },
                    { returnPromise: true }
                );
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });
                redirect('list', 'reports_simplified');
            } catch (error) {
                // The shape of the returned validation errors must match the shape of the form
                if (error.body.detail) {
                    // if (error.body.detail.current_period_end) {
                    //     notify('ra.validation.generic_date_error', {
                    //         type: 'error',
                    //     });
                    // }
                    return error.body.detail;
                }
            }
        },
        [create, notify, redirect]
    );

    return (
        <Create
            actions={<EditActions />} >
            <SimpleForm variant="standard"
                onSubmit={save} mode="onBlur" reValidateMode="onBlur"
                validate={validateSimplifiedReport}
                toolbar={<EditCreateToolbar />}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '50%', xl: '30%' }
                }} >
                    <Box flex={2} mr='2rem' sx={{ minWidth: "12rem" }}>
                        <ReferenceInput flex={1} source="client_id" reference="clients">
                            <AutocompleteInput label="Cliente" optionText="client_name" validate={required()} />
                        </ReferenceInput>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_start" defaultValue={GetFirstDayOfLastWeek} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_end" defaultValue={GetLastDayOfLastWeek} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_start" defaultValue={GetFirstDayOfTwoWeeksAgo} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_end" defaultValue={GetLastDayOfTwoWeeksAgo} validate={required()} />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '50%', xl: '30%' }
                }} >
                    <Box flex={1} mr='2rem' >
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput flex={1}
                                    source="text_resume_template_id" reference="text_templates"
                                    filter={{ "client_id": formData.client_id, "report_type": "Simplificado" }}
                                >
                                    <AutocompleteInput label="Template de texto" optionText="name"
                                        validate={required()}
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <BooleanInput source="include_fb_custom_conversions_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="include_pinads_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="include_tkads_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="send_to_whatsapp" defaultValue={true} isRequired />
                    <BooleanInput source="send_to_email" defaultValue={false} isRequired />
                </Box>
                <Box mt="3rem"></Box>
            </SimpleForm >
        </Create>
    )
}

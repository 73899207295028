export const validateSimplifiedReport = (values) => {
    const errors = {};
    if (Date.parse(values.current_period_end) < Date.parse(values.current_period_start)) {
        errors.current_period_end = 'ra.validation.end_date_error'
    }
    if (Date.parse(values.previous_period_end) < Date.parse(values.previous_period_start)) {
        errors.previous_period_end = 'ra.validation.end_date_error'
    }
    if (Date.parse(values.current_period_start) < Date.parse(values.previous_period_end)) {
        errors.previous_period_end = 'ra.validation.previous_end_date_error'
    }
    if (!values.client_id) {
        errors.client_id = 'Nenhum cliente selecionado';
    }
    if (!values.text_resume_template_id) {
        errors.text_resume_template_id = 'Nenhum template selecionado';
    }
    return errors
}
import * as React from "react";
import { Admin, Resource, CustomRoutes, useTheme } from 'react-admin';
import { Route } from "react-router-dom";

import myDataProvider from './api/MyDataProvider';
import cacheQueryClient from './api/CacheQueryClient';
import authProvider from "./api/authProvider";
import MyLoginPage from './api/MyLoginPage.tsx';

import Clients from './resources/clients/Clients';
import Reports from './resources/reports/Reports';
import ReportsSimplified from './resources/reports_simplified/ReportsSimplified';
import TextTemplates from './resources/text_templates/TextTemplates';
import Elementor from './resources/elementor_templates/Elementor';
import GetTokens from './resources/GetTokens';

import { i18nProvider } from './translation/i18nProvider';
import MyLayout from './layout/MyLayout';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import ViewCompactOutlinedIcon from '@mui/icons-material/ViewCompactOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SecurityIcon from '@mui/icons-material/Security';


const App = () => {
  const [theme, ] = useTheme()
  return (
    <Admin
      dataProvider={myDataProvider}
      authProvider={authProvider}
      loginPage={MyLoginPage}
      queryClient={cacheQueryClient}
      i18nProvider={i18nProvider}
      theme={theme}
      layout={MyLayout}
    >
      <Resource name="clients" {...Clients} icon={PersonOutlineOutlinedIcon} />
      <Resource name="text_templates" {...TextTemplates} icon={TextSnippetOutlinedIcon} />
      <Resource name="elementor_templates" {...Elementor} icon={ViewCompactOutlinedIcon} />
      <Resource name="reports" {...Reports} icon={AssessmentOutlinedIcon} />
      <Resource name="reports_simplified" {...ReportsSimplified} icon={WhatsAppIcon} />
      <CustomRoutes>
        <Route path="/tokens" element={<GetTokens />}/>
      </CustomRoutes>
      <Resource name="tokens" options={{ label: 'Tokens' }} icon={SecurityIcon} />
    </Admin>
  );
}

export default App;
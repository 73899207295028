import { ElementorList } from "./ElementorList"
import { ElementorCreate } from "./ElementorCreate"
import { ElementorEdit } from "./ElementorEdit"
import { ElementorShow } from "./ElementorShow"


const Elementor = {
    list: ElementorList,
    create: ElementorCreate,
    edit: ElementorEdit,
    show: ElementorShow
};

export default Elementor
import React from 'react';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import { useMutation } from 'react-query';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

export const DelAnnotationButton = ({ client_id, event_date }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation( 
        () => dataProvider.delAnnotation(`clients/delete_annotation`, {
            data: {
                "id": client_id,
                "event_date": event_date,
                "annotation": "",
            }
        }),
        {
            onSuccess: () => {
                notify('ra.notification.del_annotation', { type: 'success' });
                refresh()
            },
            onError: (error) => {
                notify("Não foi possível deletar o evento", { type: 'error' })
            }
        }
    );

    return (
        <IconButton
            onClick={() => mutate()}
            disabled={isLoading}
            color="primary"
            aria-label="delete"
        >
            <EventBusyOutlinedIcon />
        </IconButton>
    )
}


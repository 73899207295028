import * as React from "react";
import {
    Edit, SimpleForm, DateInput, ReferenceInput, AutocompleteInput, BooleanInput,
    required,
} from 'react-admin';
import EditCreateToolbar from "../../toolbars/EditCreateToolbar";
import { EditActions } from "../../toolbars/EditActions"
import { Box } from '@mui/material';
import { RichTextInput } from "ra-input-rich-text";
import { validateReportDates } from '../validateReportDates'



export const ReportsSimplifiedEdit = () => {

    return (
        <Edit
            actions={<EditActions />} >
            <SimpleForm variant="standard"
                validate={validateReportDates}
                toolbar={<EditCreateToolbar />}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '50%', xl: '30%' }
                }} >
                    <Box flex={2} mr='2rem' sx={{ minWidth: "12rem" }}>
                        <ReferenceInput flex={1} source="client_id" reference="clients">
                            <AutocompleteInput label="Cliente" optionText="client_name" validate={required()} />
                        </ReferenceInput>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_start" validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_end" validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_start" validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_end" validate={required()} />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: "100%"
                }} >
                    <RichTextInput label=" " source="text_resume_template" fullWidth />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <BooleanInput source="include_fb_custom_conversions_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="include_pinads_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="include_tkads_in_total" defaultValue={true} isRequired />
                </Box>
                <Box mt="3rem"></Box>
            </SimpleForm >
        </Edit>
    )
}

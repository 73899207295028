import { TextTemplatesList } from "./TextTemplatesList"
import { TextTemplatesCreate } from "./TextTemplatesCreate"
import { TextTemplatesEdit } from "./TextTemplatesEdit"
import { TextTemplatesShow } from "./TextTemplatesShow"

const TextTemplates = {
    list: TextTemplatesList,
    create: TextTemplatesCreate,
    edit: TextTemplatesEdit,
    show: TextTemplatesShow
};

export default TextTemplates
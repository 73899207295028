import * as React from "react";
import { SaveButton, DeleteButton, CloneButton, Toolbar, } from 'react-admin';


const EditCreateToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton
                label="ra.action.save_and_go_to_list"
                disabled={props.pristine}
                sx={{
                    ml: '1em',
                }}
            />
            <CloneButton variant="outlined"
                sx={{
                    ml: '1em',
                }}
            />
            <DeleteButton
                sx={{
                    ml: '1em',
                }}
            />
        </Toolbar>
    )
};

export default EditCreateToolbar
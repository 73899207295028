import { Datagrid, List, TextField, SimpleList, EditButton, ShowButton, CloneButton } from 'react-admin';
import { useMediaQuery } from '@mui/material';
// import { ClientListActions } from "../../toolbars/ClientListActions"
// import { Filters } from "../../toolbars/ClientFilters"

export const ElementorList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
        // actions={<ClientListActions/>}
        // filters={Filters} filterDefaultValues={{ active: true }}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => record.name}
                    // secondaryText={record => `id no WP: ${record.wp_id}`}
                    // tertiaryText={record => `Tipo: ${record.ga_metrics_type}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    {/* <TextField source="_elementor_data" />
                    <TextField source="_elementor_controls_usage" />
                    <TextField source="_edit_last" />
                    <TextField source="_wp_page_template" />
                    <TextField source="_edit_lock" />
                    <TextField source="_elementor_edit_mode" />
                    <TextField source="_elementor_template_type" />
                    <TextField source="_elementor_version" /> */}
                    <EditButton label="" />
                    <ShowButton label="" />
                    <CloneButton label="" />
                </Datagrid>
            )
            }
        </List>
    )
}

export default ElementorList;
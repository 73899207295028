import React, { useState, useCallback } from "react";
import {
    Datagrid, TextField, TextInput, NumberInput, BooleanInput, Edit, FormTab, TabbedForm, DateField, ReferenceManyField, ArrayInput, SimpleFormIterator,
    SelectInput, FormDataConsumer, ReferenceInput, AutocompleteInput
} from 'react-admin';
import AnnotationsField from '../../custom_fields/AnnotationsField';
import AnnotationDialog from './AnnotationDialog'
import EditCreateToolbar from "../../toolbars/EditCreateToolbar";
import { EditActions } from "../../toolbars/EditActions"
import PageTitle from '../../other_components/PageTitle.tsx';
import { Box, Divider, Typography, Tooltip } from '@mui/material';

export const ClientsEdit = () => {
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    return (
        <Edit
            actions={<EditActions />}
            title={<PageTitle preText="Editando cliente: " source="client_name" />} >
            <TabbedForm variant="standard" shouldUnregister
                toolbar={<EditCreateToolbar />}
            >
                <FormTab label="Principal">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '80%', lg: '60%', xl: '50%' }
                    }} >
                        <Box flex={2} mr='1em'>
                            <TextInput disabled source="_id" fullWidth />
                        </Box>
                        <Box flex={1} >
                            <BooleanInput source="active" fullWidth />
                        </Box>
                        <Tooltip title="Somente incluir conversões personalizadas no total de conversões do Facebook Ads?" arrow>
                            <Box flex={1} >
                                <BooleanInput source="only_custom_conversions_on_fb" fullWidth />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Divider flexItem />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        width: 'auto',
                    }} >

                        <Box flex={3} mr='1em' minWidth={'10rem'} >
                            <TextInput source="client_name" isRequired fullWidth />
                        </Box>
                        <Tooltip title="ID do cliente no WordPress" arrow>
                            <Box flex={1} mr='1em' minWidth={'7rem'}>
                                <NumberInput source="wp_id" isRequired fullWidth />
                            </Box>
                        </Tooltip>
                        <Tooltip title="Slug para formação da url no WordPress" arrow>
                            <Box flex={2} mr='1em' minWidth={'8rem'} >
                                <TextInput source="wp_slug" isRequired fullWidth />
                            </Box>
                        </Tooltip>
                        <Box flex={2} minWidth={'8rem'} >
                            <SelectInput source="ga_metrics_type" isRequired fullWidth
                                emptyValue={'leads'}
                                defaultValue={'leads'}
                                choices={[
                                    { id: 'leads', name: 'leads' },
                                    { id: 'ecommerce', name: 'ecommerce' },
                                ]} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '60%' }
                        }}>
                        <TextInput source="data_studio_url" fullWidth />
                    </Box>
                    <Divider flexItem />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: '100%',
                        }}>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                flexWrap: 'wrap',
                                width: { xs: '100%', sm: '50%', lg: '47%', xl: '47%' },
                                marginRight: '2%'
                            }}>
                            <Tooltip title="Conversões personalizadas do Facebook Ads" arrow>
                                <Typography variant='h6' >
                                    Conversões customizadas
                                </Typography>
                            </Tooltip>
                            <ArrayInput source="custom_conversion_names" label={false} >
                                <SimpleFormIterator disableReordering >
                                    <TextInput source="name" />
                                    <TextInput source="conversion_id" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                        <Divider flexItem orientation="vertical" />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                flexWrap: 'wrap',
                                width: { xs: '100%', sm: '50%', lg: '47%', xl: '47%' },
                                marginLeft: '2%'
                            }}>
                            <Tooltip title="Agrupará todos os resultados de campanhas que contém o texto no nome." arrow>
                                <Typography variant='h6' >
                                    Agrupamentos para relatórios
                                </Typography>
                            </Tooltip>
                            <ArrayInput source="group_type_list" label={false} >
                                <SimpleFormIterator disableReordering >
                                    <TextInput />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                    <Divider flexItem />
                    <Typography variant='h6' >
                        ID das plataformas
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: 'auto',
                    }} >
                        <Box mr={'1rem'} minWidth='8rem' >
                            <TextInput source="ads_platforms.google_ads" />
                        </Box>
                        <Box mr={'1rem'} minWidth='8rem' >
                            <TextInput source="ads_platforms.facebook_ads" />
                        </Box>
                        <Box minWidth='8rem' mb={'3rem'} >
                            <TextInput source="ads_platforms.ga4" />
                        </Box>
                        <Box minWidth='8rem' mb={'3rem'} >
                            <TextInput source="ads_platforms.pinterest_ads" />
                        </Box>
                        <Box minWidth='8rem' mb={'3rem'} >
                            <TextInput source="ads_platforms.tiktok_ads" />
                        </Box>
                        <Box minWidth='8rem' mb={'3rem'} >
                            <TextInput source="ads_platforms.linkedin_ads" />
                        </Box>
                    </Box>
                    <Divider flexItem />
                    <Typography variant='h6' >
                        Agendamento
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: 'auto',
                    }} >
                        <Box mr={'1rem'} minWidth='8rem' >
                            <BooleanInput source="weekly_report_schedule.weekly_report_schedule" />
                        </Box>
                        <Box mr={'1rem'} minWidth='8rem' >
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                                    <BooleanInput source="weekly_report_schedule.send_to_email"  {...rest} />
                                }
                            </FormDataConsumer>
                        </Box>
                        <Box mr={'1rem'} minWidth='8rem' >
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                                    <BooleanInput source="weekly_report_schedule.send_to_whatsapp"  {...rest} />
                                }
                            </FormDataConsumer>
                        </Box>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                                <ReferenceInput flex={1} source="weekly_report_schedule.text_resume_template_id" reference="text_templates"
                                    filter={{ "client_id": formData._id, "report_type": "Simplificado" }}
                                    {...rest}
                                >
                                    <AutocompleteInput optionText="name"
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                    <Typography variant='h6' >
                        Lista de emails
                    </Typography>
                    <ArrayInput source="emails" label={false} >
                        <SimpleFormIterator disableReordering >
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Eventos" sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <AnnotationsField source="annotations" />
                    <AnnotationDialog onChange={handleChange} />
                </FormTab>


                <FormTab label="Relatórios">
                    <ReferenceManyField label={false} reference="reports" target="client_config_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" sx={{
                            display: { xs: 'none', md: 'inline' },
                        }}>
                            <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <DateField source="current_period_start" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <DateField source="current_period_end" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <TextField source="payload.title" />
                            <TextField source="report_wp_id" />
                            <TextField source="report_wp_url" />
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField label={false} reference="reports" target="client_config_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" sx={{
                            display: { xs: 'inline', md: 'none' },
                        }}>
                            <TextField source="payload.title" />
                            <TextField source="report_wp_url" label='URL' />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="Textos">
                    <ReferenceManyField label={false} reference="text_templates" target="client_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" >
                            <TextField source="name" />
                            <TextField source="report_type" />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>

            </TabbedForm >
        </Edit>
    )
};

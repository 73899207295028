import React from 'react';
import { useRecordContext } from 'react-admin';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { DelAnnotationButton } from '../resources/clients/DelAnnotationButton'
import { Box } from '@mui/material';

const AnnotationsField = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            minWidth: '30%',
        }}>
            <Timeline position="right">
                {record?.annotations?.sort((a, b) => a?.event_date.localeCompare(b.event_date))
                    .map((item, idx) => (
                        <React.Fragment key={item?.event_date + idx}>

                            <TimelineItem >
                                <TimelineOppositeContent color="text.secondary">
                                    {item?.event_date}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {/* Use color from the record, create list of options */}
                                    <TimelineDot variant="outlined" color='primary' />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    {item?.annotation}
                                </TimelineContent>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                }}>
                                    <DelAnnotationButton
                                        event_date={item?.event_date}
                                        client_id={record.id} />
                                </Box>
                            </TimelineItem>

                        </React.Fragment>
                    ))
                }

            </Timeline >
        </Box>)
}

AnnotationsField.defaultProps = { addLabel: true }

export default AnnotationsField
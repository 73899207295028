import React, { useState, useCallback } from 'react';
import {
    Datagrid, TextField, Show, BooleanField, NumberField, DateField, ReferenceManyField, TabbedShowLayout, Tab, Labeled, ChipField,
    useRecordContext, ReferenceField
} from 'react-admin';
import TextArrayField from '../../custom_fields/TextArrayField';
import UrlFieldEllipsis from '../../custom_fields/UrlFieldEllipsis';
import ConversionNamesField from '../../custom_fields/ConversionNamesField';
import AnnotationsField from '../../custom_fields/AnnotationsField';
import PageTitle from '../../other_components/PageTitle.tsx';
import { ShowActions } from "../../toolbars/ShowActions"

import { Box, Divider, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import SimplifiedReportDialog from './SimplifiedReportDialog'
import AnnotationDialog from './AnnotationDialog'


export const ClientsShow = () => {
    const theme = useTheme()
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    const ConditionalScheduleFields = () => {
        const record = useRecordContext();
        return (
            record && record?.weekly_report_schedule && record?.weekly_report_schedule?.weekly_report_schedule ?
                <>
                    <Divider flexItem />
                    <Typography variant='h6' sx={{ paddingBottom: '1rem' }} >
                        Agendamento
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: 'auto',
                        gap: '3rem'
                    }} >
                        <Labeled>
                            <BooleanField source="weekly_report_schedule.send_to_whatsapp" />
                        </Labeled>
                        <Labeled>
                            <BooleanField source="weekly_report_schedule.send_to_email" />
                        </Labeled>
                        <Labeled>
                            <ReferenceField source="weekly_report_schedule.text_resume_template_id" reference="text_templates" >
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Box>
                </> : null
        )
    }

    const ConditionalemailField = () => {
        const record = useRecordContext();
        return (
            record && (record?.emails?.length > 0) ?
                <>
                    <Labeled>
                        <TextArrayField label="emails" source="emails" />
                    </Labeled>
                </> : null
        )
    }

    const ConditionalGroupTypeField = () => {
        const record = useRecordContext();
        return (
            record && (record?.group_type_list?.length > 0) ?
                <>
                    <Box>
                        <Labeled sx={{
                            alignItems: { xs: 'left', md: 'center' },
                        }}>
                            <TextArrayField source="group_type_list" />
                        </Labeled>
                    </Box>
                </> : null
        )
    }


    const ConditionalCustomConversionsField = () => {
        const record = useRecordContext();
        return (
            record && (record.custom_conversion_names?.length > 0) ?
                <>
                    <Box sx={{
                        marginLeft: { xs: '0', md: '1rem', lg: '2rem', xxl: '3rem' },
                    }}>
                        <Labeled sx={{
                            alignItems: { xs: 'left', md: 'center' },
                        }}>
                            <ConversionNamesField source="custom_conversion_names" />
                        </Labeled>
                    </Box>
                </> : null
        )
    }

    const ConditionalGoogleAdsField = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record.ads_platforms?.google_ads ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.google_ads" color='warning' /></Labeled>
                </> : null
        )
    }

    const ConditionalFacebookAdsField = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record.ads_platforms?.facebook_ads ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.facebook_ads" sx={{ bgcolor: '#1877f2' }} /></Labeled>
                </> : null
        )
    }


    const ConditionalGA4Field = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record?.ads_platforms?.ga4 ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.ga4" sx={{ bgcolor: '#ee7b09' }} /></Labeled>
                </> : null
        )
    }

    const ConditionalPinterestAdsField = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record?.ads_platforms?.pinterest_ads ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.pinterest_ads" sx={{ bgcolor: '#d30214' }} /></Labeled>
                </> : null
        )
    }

    const ConditionalLinkedInAdsField = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record?.ads_platforms?.linkedin_ads ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.linkedin_ads" sx={{ bgcolor: '#0077b7' }} /></Labeled>
                </> : null
        )
    }

    const ConditionalTikTokAdsField = () => {
        const record = useRecordContext();
        return (
            record && record?.ads_platforms && record?.ads_platforms?.tiktok_ads ?
                <>
                    <Labeled sx={{
                        alignItems: { xs: 'left', md: 'center' },
                        marginRight: { xs: '1rem', lg: '1.5rem' },
                    }} >
                        <ChipField source="ads_platforms.tiktok_ads" sx={{bgColor: '#e62a5d'}} /></Labeled>
                </> : null
        )
    }

    return (

        <Show title={<PageTitle preText="Cliente: " source="client_name" />}
            actions={<ShowActions />} >
            <TabbedShowLayout >
                <Tab label="Principal">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '80%', lg: '90%', xl: '50%' },
                        padding: '1rem',
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: '1rem',
                    }} >
                        <Box flex={3} mr={'2rem'} sx={{ whiteSpace: "nowrap" }} >
                            <Labeled><TextField source="client_name" /></Labeled>
                        </Box>
                        <Box flex={1} mr={'2rem'} >
                            <Labeled><BooleanField source="active" /></Labeled>
                        </Box>
                        <Box flex={2} mr={'2rem'} >
                            <Labeled><BooleanField source="only_custom_conversions_on_fb"
                                valueLabelTrue="Somará apenas conversões personalizadas nos totais do Facebook Ads"
                                valueLabelFalse="Somará todas as conversões nos totais do Facebook Ads" /></Labeled>
                        </Box>
                        <Box flex={1} mr={'1rem'} sx={{ whiteSpace: "nowrap" }} >
                            <Labeled><NumberField source="wp_id" /></Labeled>
                        </Box>
                        <Box flex={2} mr={'1rem'} sx={{ whiteSpace: "nowrap" }} >
                            <Labeled><TextField source="wp_slug" /></Labeled>
                        </Box>
                        <Box flex={2} mr={'1rem'} sx={{ whiteSpace: "nowrap" }} >
                            <Labeled><TextField source="ga_metrics_type" /></Labeled>
                        </Box>
                        <Box flex={2} mr={'1rem'} >
                            <Labeled><TextField source="id" label="ID" /></Labeled>
                        </Box>
                    </Box>
                    <Box>
                        <Labeled>
                            <UrlFieldEllipsis source="data_studio_url" emptyText="Não preenchido" target='_blank' />
                        </Labeled>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >

                        <ConditionalGroupTypeField />
                        <ConditionalCustomConversionsField />

                        <Box
                            sx={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginLeft: { xs: '0', md: '1rem', lg: '2rem', xxl: '3rem' },
                            }} >
                            <ConditionalGoogleAdsField />
                            <ConditionalFacebookAdsField />
                            <ConditionalGA4Field />
                            <ConditionalPinterestAdsField />
                            <ConditionalTikTokAdsField />
                            <ConditionalLinkedInAdsField />
                        </Box>
                    </Box>
                    <ConditionalScheduleFields />
                    <ConditionalemailField />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: '2rem',
                        }} >
                        <Box>
                            <SimplifiedReportDialog onChange={handleChange} />
                        </Box>
                        <Box>
                            <AnnotationDialog onChange={handleChange} />
                        </Box>
                    </Box>
                </Tab>

                <Tab label="Eventos">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <AnnotationsField source="annotations" />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: '2rem',
                        }} >
                        <Box>
                            <SimplifiedReportDialog onChange={handleChange} />
                        </Box>
                        <Box>
                            <AnnotationDialog onChange={handleChange} />
                        </Box>
                    </Box>
                </Tab>

                <Tab label="Relatórios">
                    <ReferenceManyField label={false} reference="reports" target="client_config_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" sx={{
                            display: { xs: 'none', md: 'inline' },
                        }}>
                            <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <DateField source="current_period_start" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <DateField source="current_period_end" locales="pt-br" options={{ timeZone: 'UTC' }} />
                            <TextField source="payload.title" />
                            <TextField source="report_wp_id" />
                            <TextField source="report_wp_url" />
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField label={false} reference="reports" target="client_config_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" sx={{
                            display: { xs: 'inline', md: 'none' },
                        }}>
                            <TextField source="payload.title" />
                            <TextField source="report_wp_url" label='URL' />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Textos">
                    <ReferenceManyField label={false} reference="text_templates" target="client_id" sort={{ field: "current_period_start", order: "DESC" }} >
                        <Datagrid rowClick="show" >
                            <TextField source="name" />
                            <TextField source="report_type" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show >
    )
}

import { ReportsSimplifiedList } from "./ReportsSimplifiedList"
import { ReportsSimplifiedCreate } from "./ReportsSimplifiedCreate"
import { ReportsSimplifiedEdit } from "./ReportsSimplifiedEdit"
import { ReportsSimplifiedShow } from "./ReportsSimplifiedShow"


const ReportsSimplified = {
    list: ReportsSimplifiedList,
    create: ReportsSimplifiedCreate,
    edit: ReportsSimplifiedEdit,
    show: ReportsSimplifiedShow
};

export default ReportsSimplified
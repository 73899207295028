import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

const TextArrayField = ({ source }) => {
    const record = useRecordContext();
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div />
    } else {
        return (
            <div>
                {array.map(item => <Chip label={item} key={item} 
                sx={{
                    margin: '0.5rem',
                }}
                />)}
            </div>
        )
    }
}

TextArrayField.defaultProps = { addLabel: true }

export default TextArrayField
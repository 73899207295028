import * as React from "react";
import { SaveButton, DeleteButton, CloneButton, Toolbar, } from 'react-admin';
// import { SaveShowButton } from "../buttons/SaveShowButton";


const EditReportToolbar = props => {
    // const { source, pretext, field } = props;
    return (
        <Toolbar {...props} >
            {/* <SaveShowButton
            source={source}
            pretext={pretext}
            field={field}
        /> */}
            <SaveButton
                alwaysEnable
                label="ra.action.update"
                // redirect="list"
                // submitOnEnter={false}
                disabled={props.pristine}
                sx={{
                    ml: '1em',
                }}
            />
            <CloneButton variant="outlined"
                sx={{
                    ml: '1em',
                }}
            />
            <DeleteButton
                sx={{
                    ml: '1em',
                }}
            />
        </Toolbar>
    )
};

export default EditReportToolbar
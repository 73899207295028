// import * as React from "react";
import {
    SimpleForm, TextInput, NumberInput, BooleanInput, Create, ArrayInput, SimpleFormIterator, SelectInput,
    FormDataConsumer, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { Box, Divider, Typography, Tooltip } from '@mui/material';

// const validateMinNumbers = [number(), minValue(0)]
// const validateLength = [maxLength(12)]

export const ClientsCreate = () => {
    return (
        <Create>
            <SimpleForm shouldUnregister >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '80%', lg: '60%', xl: '50%' }
                }} >
                    <Box flex={1} >
                        <BooleanInput source="active" fullWidth />
                    </Box>
                    <Tooltip title="Somente incluir conversões personalizadas no total de conversões do Facebook Ads?" arrow>
                        <Box flex={1} >
                            <BooleanInput source="only_custom_conversions_on_fb" fullWidth />
                        </Box>
                    </Tooltip>
                </Box>
                <Divider flexItem />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: 'auto',
                }} >

                    <Box flex={3} mr='1em' minWidth={'10rem'} >
                        <TextInput source="client_name" isRequired fullWidth />
                    </Box>
                    <Box flex={1} mr='1em' minWidth={'7rem'}>
                        <NumberInput source="wp_id" isRequired fullWidth />
                    </Box>
                    <Box flex={2} mr='1em' minWidth={'8rem'} >
                        <TextInput source="wp_slug" isRequired fullWidth />
                    </Box>
                    <Box flex={2} minWidth={'8rem'} >
                        <SelectInput source="ga_metrics_type" isRequired fullWidth
                            emptyValue={'leads'}
                            defaultValue={'leads'}
                            choices={[
                                { id: 'leads', name: 'leads' },
                                { id: 'ecommerce', name: 'ecommerce' },
                            ]} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '80%', xl: '60%' }
                    }}>
                    <TextInput source="data_studio_url" fullWidth />
                </Box>
                <Divider flexItem />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '50%', lg: '47%', xl: '47%' },
                            marginRight: '2%'
                        }}>
                        <Tooltip title="Conversões personalizadas do Facebook Ads" arrow>
                            <Typography variant='h6' >
                                Conversões customizadas
                            </Typography>
                        </Tooltip>
                        <ArrayInput source="custom_conversion_names" label={false} >
                            <SimpleFormIterator disableReordering >
                                <TextInput source="name" />
                                <TextInput source="conversion_id" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                    <Divider flexItem orientation="vertical" />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '50%', lg: '47%', xl: '47%' },
                            marginLeft: '2%'
                        }}>
                        <Tooltip title="Agrupará todos os resultados de campanhas que contém o texto no nome." arrow>
                            <Typography variant='h6' >
                                Agrupamentos para relatórios
                            </Typography>
                        </Tooltip>
                        <ArrayInput source="group_type_list" label={false} >
                            <SimpleFormIterator disableReordering >
                                <TextInput />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </Box>
                <Divider flexItem />
                <Typography variant='h6' >
                    ID das plataformas
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: 'auto',
                }} >
                    <Box mr={'1rem'} minWidth='8rem' >
                        <TextInput source="ads_platforms.google_ads" />
                    </Box>
                    <Box mr={'1rem'} minWidth='8rem' >
                        <TextInput source="ads_platforms.facebook_ads" />
                    </Box>
                    <Box minWidth='8rem' mb={'3rem'} >
                            <TextInput source="ads_platforms.ga4" />
                        </Box>
                    <Box minWidth='8rem' mb={'3rem'} >
                        <TextInput source="ads_platforms.pinterest_ads" />
                    </Box>
                    <Box minWidth='8rem' mb={'3rem'} >
                        <TextInput source="ads_platforms.tiktok_ads" />
                    </Box>
                    <Box minWidth='8rem' mb={'3rem'} >
                        <TextInput source="ads_platforms.linkedin_ads" />
                    </Box>
                </Box>
                <Divider flexItem />
                <Typography variant='h6' >
                    Agendamento
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: 'auto',
                }} >
                    <Box mr={'1rem'} minWidth='8rem' >
                        <BooleanInput source="weekly_report_schedule.weekly_report_schedule" />
                    </Box>
                    <Box mr={'1rem'} minWidth='8rem' >
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                                <BooleanInput source="weekly_report_schedule.send_to_email"  {...rest} />
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box mr={'1rem'} minWidth='8rem' >
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                                <BooleanInput source="weekly_report_schedule.send_to_whatsapp"  {...rest} />
                            }
                        </FormDataConsumer>
                    </Box>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData.weekly_report_schedule && formData.weekly_report_schedule.weekly_report_schedule &&
                            <ReferenceInput flex={1} source="weekly_report_schedule.text_resume_template_id" reference="text_templates"
                                filter={{ "client_id": formData._id, "report_type": "Simplificado" }}
                                {...rest}
                            >
                                <AutocompleteInput optionText="name"
                                    helperText="ra.helperText.template_text" />
                            </ReferenceInput>
                        }
                    </FormDataConsumer>
                </Box>
                <Typography variant='h6' >
                        Lista de emails
                    </Typography>
                    <ArrayInput source="emails" label={false} >
                        <SimpleFormIterator disableReordering >
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
            </SimpleForm >
        </Create>
    )
}


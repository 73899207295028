import {
    Datagrid, List, TextField, SimpleList, EditButton, ShowButton, CloneButton,
    DateField, UrlField
}
    from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ClientListActions } from "../../toolbars/ClientListActions"
import { Filters } from "../../toolbars/ClientFilters"
import { ListPagination } from '../../other_components/ListPagination';

export const ReportsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            sort={{ field: 'current_period_start', order: 'DESC' }}
            actions={<ClientListActions />}
            filters={Filters} filterDefaultValues={{ active: true }}
            pagination={<ListPagination />} perPage={20}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => record.payload.title}
                    secondaryText={record => `Cliente: ${record.client_config.client_name}`}
                    tertiaryText={record => `Início: ${record.current_period_end}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="client_config.client_name" />
                    <TextField source="payload.title" />
                    <DateField source="current_period_start" />
                    <DateField source="current_period_end" />
                    <UrlField source="report_wp_url"
                    target='_blank'
                        sx={{
                            display: "block",
                            width: "10rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            direction: "rtl",
                            '&:hover, &:focus, &:active': {
                                whiteSpace: "normal",
                                overflow: "visible",
                                textOverflow: "clip",
                                wordBreak: "break-all"
                            },
                        }} />
                    {/* <TextField source="payload.titulo" /> */}
                    <TextField source="report_wp_id" label="ID WP" />
                    <EditButton label="" />
                    <ShowButton label="" />
                    <CloneButton label="" />
                </Datagrid>
            )
            }
        </List>
    )
}

export default ReportsList;
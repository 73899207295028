import { apiUrl } from "./MyDataProvider";

// const apiUrl = 'https://fast.diegogaona.com'

const authProvider = {
    login: async ({ username, password }) => {
        try {
            const oAuthParams = {
                username,
                password
            }
            const form = Object.keys(oAuthParams).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(oAuthParams[key]);
              }).join('&');
            const payload = {
                method: 'POST',
                body: form,
                headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
            }

            const request = new Request(`${apiUrl}/auth/login`, payload)
            
            const response = await fetch(request, { credentials: 'include' })            
            
            if (response.status < 200 || response.status >= 300) {
                const data = await response.json();
                throw new Error(data.detail)
            }

            return localStorage.setItem('authenticated', true);
        } catch (error) {
            console.error(error)
            throw new Error(`ra.auth.${error}` || "Network error :(");
        }
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('authenticated');
            return Promise.reject({redirectTo: '/login'});
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('authenticated')
    ? Promise.resolve()
    : Promise.reject({ message: 'ra.notification.needLogin', redirectTo: '/login' }),

    logout: async () => {
        try {
        const payload = {
            method: 'POST',
            body: "",
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        }
        
        const request = new Request(`${apiUrl}/auth/logout`, payload)
        if( localStorage.getItem('authenticated')) {
        const response = await fetch(request, { credentials: 'include' })
        

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
        }}
        
        localStorage.removeItem('authenticated');
        return Promise.resolve();        

    } catch (error) {
        return Promise.reject({redirectTo: '/login'});
    }
    },

    getPermissions: () => {
        return Promise.resolve();
    },
    getIdentity: async () => {
        // try {
        // const request = new Request(`${apiUrl}/users/me`)
        // const response = await fetch(request, { credentials: 'include' })
        // let userData = await response.json();
        // // console.log(commits.email)
        // return userData.email
        return Promise.resolve();
    // }
    // catch (error) {
    //     return Promise.reject();
    // }
}
};

export default authProvider;
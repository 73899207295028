import {
    Datagrid, List, TextField, SimpleList, ShowButton,
    DateField, EditButton
}
    from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ClientListActions } from "../../toolbars/ClientListActions"
import { Filters } from "../../toolbars/ClientFilters"
import { ListPagination } from '../../other_components/ListPagination';

export const ReportsSimplifiedList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            sort={{ field: 'current_period_start', order: 'DESC' }}
            actions={<ClientListActions />}
            filters={Filters} filterDefaultValues={{ active: true }}
            pagination={<ListPagination />} perPage={20}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => `Cliente: ${record.client_config.client_name}`}
                    secondaryText={record => `Início: ${record.current_period_end}`}
                    // tertiaryText={record => `Início: ${record.current_period_end}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="client_config.client_name" />
                    <DateField source="current_period_start" />
                    <DateField source="current_period_end" />                    
                    <EditButton label="" />
                    <ShowButton label="" />
                    {/* <CloneButton label="" /> */}
                </Datagrid>
            )
            }
        </List>
    )
}

export default ReportsSimplifiedList;
import {
    TextField, Show, SimpleShowLayout, Labeled, RichTextField, DateField
} from 'react-admin';
import PageTitle from '../../other_components/PageTitle.tsx';
import { ShowActions } from "../../toolbars/ShowActions"

import { Box, Divider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';


export const TextTemplatesShow = () => {
    const theme = useTheme()

    return (

        <Show title={<PageTitle preText="Texto: " source="name" />}
            actions={<ShowActions />} >
            <SimpleShowLayout >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '80%', lg: '90%', xl: '50%' },
                    padding: '1rem',
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '1rem',
                }} >
                    <Box flex={2} mr={'2rem'} sx={{ whiteSpace: "nowrap", minWidth: "5rem" }} >
                        <Labeled>
                            <TextField source="client.client_name" />
                        </Labeled>
                    </Box>
                    <Box flex={2} mr={'2rem'} sx={{ whiteSpace: "nowrap", minWidth: "5rem" }} >
                        <Labeled>
                            <DateField source="updated_at" showTime locales="pt-BR" />
                        </Labeled>
                    </Box>
                    <Box flex={2} mr={'2rem'}
                        sx={{ minWidth: "5rem" }}>
                        <Labeled><TextField source="name" /></Labeled>
                    </Box>
                    <Box flex={1} mr={'2rem'}
                        sx={{ minWidth: "5rem" }}>
                        <Labeled><TextField source="report_type" /></Labeled>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Labeled>
                        <RichTextField source="content" //component="pre"
                            sx={{
                                textAlign: 'justify',
                                whiteSpace: "pre-wrap",  // normal | pre | nowrap | pre-wrap | pre-line | break-spaces
                            }}
                        />
                    </Labeled>
                </Box>
            </SimpleShowLayout>
        </Show >
    )
}
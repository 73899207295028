import React, { useState, useCallback } from "react";
import {
    TextInput, Edit, FormTab, TabbedForm, DateInput, ReferenceInput, AutocompleteInput, BooleanInput,
    useGetOne, useRedirect, RecordContextProvider, required, BooleanField, Labeled, DateField
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { RichTextInput } from 'ra-input-rich-text';
import EditReportToolbar from "../../toolbars/EditReportToolbar";
import { EditActions } from "../../toolbars/EditActions"
import PageTitle from '../../other_components/PageTitle.tsx';
import { Box, Divider, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import ArticleIcon from '@mui/icons-material/Article';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { validateReportDates } from '../validateReportDates'
import ScheduleDateDialog from './ScheduleDateDialog'

export const ReportsEdit = () => {
    const redirect = useRedirect();
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    // To get the data from the client and use as filters for some fields.
    const { id } = useParams();
    const { data, isLoading } = useGetOne(
        'reports',
        { id },
        { onError: () => redirect('/reports') }
    );
    if (isLoading) { return <div />; }



    return (
        <RecordContextProvider value={data}>
            <Edit
                actions={<EditActions />}
                title={<PageTitle preText="Editando relatório: " source="payload.title" />}
                mutationMode="optimistic"
                redirect="show" >
                <TabbedForm variant="standard"
                    validate={validateReportDates}
                    toolbar={<EditReportToolbar />}
                >
                    <FormTab label='Principal' icon={<SummarizeIcon />} >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <Box flex={2} mr='2rem' sx={{ minWidth: "8rem" }}>
                                <ReferenceInput flex={1} source="client_id" reference="clients" filter={{ "active": true }} >
                                    <AutocompleteInput optionText="client_name" validate={required()} />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem' sx={{ minWidth: "7rem" }}>
                                <TextInput disabled source="report_wp_id" fullWidth />
                            </Box>
                            <Box flex={1} mr='2rem' sx={{ minWidth: "7rem" }}>
                                <TextInput disabled source="id" fullWidth />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <Box flex={1} mr='2rem'>
                                <DateInput source="current_period_start" fullWidth sx={{ minWidth: "6rem" }} validate={required()} />
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <DateInput source="current_period_end" fullWidth sx={{ minWidth: "6rem" }} validate={required()} />
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <DateInput source="previous_period_start" fullWidth sx={{ minWidth: "6rem" }} validate={required()} />
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <DateInput source="previous_period_end" fullWidth sx={{ minWidth: "6rem" }} validate={required()} />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="report_model_id" reference="elementor_templates">
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="text_resume_template_id" reference="text_templates"
                                    filter={{ "client_id": data.client_config._id, "report_type": "Resumo" }}
                                >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="text_gads_template_id" reference="text_templates"
                                    filter={{ "client_id": data.client_config._id, "report_type": "Google Ads" }} >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="text_fbads_template_id" reference="text_templates"
                                    filter={{ "client_id": data.client_config._id, "report_type": "Facebook Ads" }} >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="text_other_template_id" reference="text_templates"
                                    filter={{ "client_id": data.client_config._id, "report_type": "Outros" }} >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <Box flex={1} mr='2rem'>
                                <ReferenceInput flex={1} source="text_metrics_template_id" reference="text_templates"
                                    filter={{
                                        // "client_id": data.client_config._id, 
                                        "report_type": "Métricas"
                                    }} >
                                    <AutocompleteInput optionText="name" defaultValue={"62c886441510fac80f3db55e"} />
                                </ReferenceInput>
                            </Box>
                        </Box>
                        <Divider flexItem />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <BooleanInput source="only_test" fullWidth />
                            <BooleanInput source="update_elementor" defaultValue={true} fullWidth />
                        </Box>
                        <Divider flexItem />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <BooleanInput source="include_fb_custom_conversions_in_total" defaultValue={true} isRequired />
                            <BooleanInput source="include_pinads_in_total" defaultValue={true} isRequired />
                            <BooleanInput source="include_tkads_in_total" defaultValue={true} isRequired />
                        </Box>
                        <Divider flexItem />
                        <Typography variant='h6' >
                            Agendamento de envio
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: '2rem',
                            flexWrap: 'wrap',
                            marginTop: '1rem',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                        }} >
                            <Labeled><DateField source="send_report_date" /></Labeled>
                            <Labeled><BooleanField source="report_sent" defaultValue={false} /></Labeled>
                            <ScheduleDateDialog onChange={handleChange} />
                        </Box>
                        <Box mt="3rem"></Box>                        
                    </FormTab>
                    {/* SMALL SIZES */}
                    <FormTab label="" icon={<ArticleIcon />} sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'flex', sm: 'none' }
                    }}>
                        <RichTextInput source="text_resume_template" fullWidth label={false} />

                    </FormTab>
                    <FormTab label="" icon={<GoogleIcon />} sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'flex', sm: 'none' }
                    }}>
                        <RichTextInput source="text_gads_template" fullWidth label={false} />
                    </FormTab>
                    <FormTab label="" icon={<FacebookIcon />} sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'flex', sm: 'none' }
                    }}>
                        <RichTextInput source="text_fbads_template" fullWidth label={false} />
                    </FormTab>
                    <FormTab label="" icon={< ViewCarouselIcon />} sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'flex', sm: 'none' }
                    }}>
                        <RichTextInput source="text_other_template" fullWidth label={false} />
                    </FormTab>
                    {/* FOR OTHER SIZES */}
                    <FormTab icon={<ArticleIcon />} label="Resumo" sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'none', sm: 'flex' }
                    }}>
                        <Box sx={{ wordBreak: "break-word", width: "100%", }}>
                            <RichTextInput source="text_resume_template" fullWidth label={false} />
                        </Box>
                    </FormTab>
                    <FormTab icon={<GoogleIcon />} label="Google Ads" sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'none', sm: 'flex' }
                    }}>
                        <Box sx={{ wordBreak: "break-word", width: "100%", }}>
                            <RichTextInput source="text_gads_template" fullWidth label={false} />
                        </Box>
                    </FormTab>
                    <FormTab icon={<FacebookIcon />} label="Meta Ads" sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'none', sm: 'flex' }
                    }}>
                        <Box sx={{ wordBreak: "break-word", width: "100%", }}>
                            <RichTextInput source="text_fbads_template" fullWidth label={false} />
                        </Box>
                    </FormTab>
                    <FormTab icon={< ViewCarouselIcon />} label="Outros" sx={{
                        wordBreak: "break-word", minWidth: '4rem', display: { xs: 'none', sm: 'flex' }
                    }}>
                        <Box sx={{ wordBreak: "break-word", width: "100%", }}>
                            <RichTextInput source="text_other_template" fullWidth label={false} />
                        </Box>
                    </FormTab>
                </TabbedForm >
            </Edit>
        </RecordContextProvider>
    )
}

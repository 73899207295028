import React, { useState } from 'react';
import {
    required,
    Button,
    useNotify,
    useRefresh,
    Form,
    useGetRecordId,
    CreateBase,
    DateInput,
    useRecordContext,
    FormDataConsumer,
    useDataProvider
} from 'react-admin';
import { Box } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useMediaQuery } from '@mui/material';
import { useMutation } from 'react-query';


function ScheduleDateDialog() {
    const [showDialog, setShowDialog] = useState(false);

    const recordId = useGetRecordId()
    const record = useRecordContext()
    const title = `Alterar data de envio para ${record?.payload?.title}`
    const today = new Date().toISOString().slice(0, 10)

    const postDefaultValue = () => ({ report_id: recordId });

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const notify = useNotify();

    const ChangeDateButton = ({ report_id, send_report_date }) => {
        const dataProvider = useDataProvider();
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        const refresh = useRefresh();
        const { mutate, isLoading } = useMutation(
            () => dataProvider.addAnnotation(`reports/change_send_report_date`, {
                data: {
                    "id": report_id,
                    "send_report_date": send_report_date,
                }
            }),
            {
                onSuccess: () => {
                    notify('ra.notification.change_date', { type: 'success' });
                    handleCloseClick()
                    refresh()
                },
                onError: (error) => {
                    notify(error.body.detail.event_date, { type: 'error' })
                }
            }
        );

        return (
            <>
                {isSmall ? (
                    <Button
                        label="Alterar data de envio"
                        type="submit"
                        onClick={() => mutate()}
                        disabled={isLoading}
                        variant="outlined"
                        color="primary"
                        startIcon={<EventAvailableOutlinedIcon />}
                    />
                ) : (
                    <Button
                        label="Mudar data de envio"
                        type="submit"
                        onClick={() => mutate()}
                        disabled={isLoading}
                        variant="outlined"
                        color="primary"
                        startIcon={<EventAvailableOutlinedIcon />}
                    />
                )}
            </>
        )
    }

    // The dialog
    return (
        <>
            <Box sx={{
                maxWidth: '16rem',
                // marginTop: '3rem',
                // marginBottom: '1rem',
            }}>
                <Button onClick={handleClick} label="ra.action.change_date"
                    variant="contained"
                    color="primary"
                    startIcon={<EventAvailableOutlinedIcon />}
                    sx={{
                        minHeight: '2.5rem',
                    }}
                />
            </Box>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="ra.action.edit"
            >
                <DialogTitle>{title}</DialogTitle>
                <CreateBase resource='reports/change_send_report_date'>
                    <Form defaultValues={postDefaultValue}
                    >
                        <DialogContent>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                            }} >
                                <DateInput source="send_report_date" label="Data para envio" defaultValue={today} validate={required()}
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            event.stopPropagation();
                                        }
                                    }}
                                />
                            </Box>

                        </DialogContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap',
                        }} >
                            <DialogActions>
                                <Button
                                    sx={{ marginRight: "3rem" }}
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                >
                                    <IconCancel />
                                </Button>
                                <FormDataConsumer>
                                    {({ formData }) =>
                                        <ChangeDateButton
                                            send_report_date={formData.send_report_date}
                                            report_id={formData.report_id} />
                                    }
                                </FormDataConsumer>
                            </DialogActions>
                        </Box>
                    </Form>
                </CreateBase>
            </Dialog>
        </>
    );
}

export default ScheduleDateDialog;

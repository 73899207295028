import {
    TextField, RichTextField, Show, DateField, Labeled,
    SimpleShowLayout,
} from 'react-admin';
import PageTitle from '../../other_components/PageTitle.tsx';
import { ShowActions } from "../../toolbars/ShowActions"
import { SendReportWhatsAppButton } from "./WhatsAppMessageButton"


import { Box, } from '@mui/material';


export const ReportsSimplifiedShow = () => {

    return (

        <Show title={<PageTitle preText="Relatório para WhatsApp: " source="client_config.client_name" />}
            actions={<ShowActions />} >
            <SimpleShowLayout >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' }
                }} >
                    <Box mr='2rem'>
                        <Labeled><DateField source="created_at" locales="pt-BR" /></Labeled>
                    </Box>
                    <Box>
                        <Labeled><TextField source="client_config.client_name" /></Labeled>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' }
                }} >
                    <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><DateField source="current_period_start" locales="pt-BR" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><DateField source="current_period_end" locales="pt-BR" /></Labeled>
                        </Box>
                    </Box>
                    <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><DateField source="previous_period_start" locales="pt-BR" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><DateField source="previous_period_end" locales="pt-BR" /></Labeled>
                        </Box>
                    </Box>
                </Box>
                {/* <Box> */}
                    {/* <Labeled><RichTextField source="text_resume_template" /></Labeled> */}
                {/* </Box> */}
                <Box>
                    <Labeled><RichTextField source="text_resume" /></Labeled>
                </Box>
                <SendReportWhatsAppButton />
            </SimpleShowLayout>
        </Show >
    )
}
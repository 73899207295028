import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

// export const apiUrl = "http://localhost:8080"
export const apiUrl = (process.env.REACT_APP_BACK_END_URL)


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = jsonServerProvider(apiUrl, httpClient);

const myDataProvider = {
    ...dataProvider,
    update: async (resource, params) => {
        const { id, created_at, updated_at, owner, owner_id, exclude, ...data } = params.data;

        // don't forget to return the promise!
        try {
            const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(data),
            });
            return ({
                // data: json 
                data: { ...json, id: json._id }, // To use _id instead of id
            });
        } catch (err) {
            return console.log(err);
        }
    },

    create: async (resource, params) => {
        const { _id, id, payload, created_at, updated_at, owner, owner_id, exclude, report_wp_url, ...data } = params.data;
        const url = `${apiUrl}/${resource}/`;
        return (
            await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(data),
            }).then(({ json }) => ({
                data: { ...data, id: json._id },
            }))
        )
    },

    delete: async (resource, params) =>
        await httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({
            data: { ...params.data, id: json._id },
        })),


    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = await {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        // Modified to use / in the end of the url
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        const { headers, json } = await httpClient(url);
        if (!headers.has('x-total-count')) {
            throw new Error(
                'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            );
        }
        return {
            // data: json,
            data: await json.map(resource => ({ ...resource, id: resource._id })), // To use _id instead of id
            total: parseInt(
                headers.get('x-total-count').split('/').pop(),
                10
            ),
        };
    },

    getOne: async (resource, params) =>
        await httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            // data: json,
            data: { ...json, id: json._id }, // To use _id instead of id
        })),

    getMany: async (resource, params) => {
        const query = {
            _id: params.ids,
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;
        const { json } = await httpClient(url);
        return ({
            // data: json 
            data: json.map(resource => ({ ...resource, id: resource._id })),
        });
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        const { headers, json } = await httpClient(url);
        if (!headers.has('x-total-count')) {
            throw new Error(
                'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            );
        }
        return {
            // data: json,
            data: await json.map(resource => ({ ...resource, id: resource._id })), // To use _id instead of id
            total: parseInt(
                headers.get('x-total-count').split('/').pop(),
                10
            ),
        };
    },

    sendSimplified: (resource, params) => {
        return (
            httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        )
    },
    sendReport: (resource, params) => {
        return (
            httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        )
    },

    addAnnotation: (resource, params) => {
        return (
            httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        )
    },

    delAnnotation: (resource, params) => {
        return (
            httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
                method: 'DELETE',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        )
    },

    change_send_report_date: (resource, params) => {
        return (
            httpClient(`${apiUrl}/${resource}/${params.data.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
        )
    },


};


export default myDataProvider
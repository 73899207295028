
import { Box } from '@mui/material';
import { styled } from '@mui/system';


export const CellBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    padding: '0.5rem',
    borderRadius: '0.5rem',
    boxShadow: 2,
    minWidth: '5rem',
    textAlign: 'center',
    margin: '0.5rem',
}));

import {
    TopToolbar, ListButton, ShowButton, DeleteButton
} from 'react-admin';

export const EditActions = ({ data }) => (
    <TopToolbar>
        <ListButton />
        <ShowButton record={data} />
        <DeleteButton record={data} />
    </TopToolbar>
);

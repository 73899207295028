import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';

const ConversionNamesField = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
    <div>
        {record.custom_conversion_names.map(item => (
            <Chip key={item.name} label={`${item.name}: ${item.conversion_id}`} 
            color="error"
            sx={{
                margin: '0.5rem',
            }}
            />
        ))}
    </div>
    )
}

ConversionNamesField.defaultProps = { addLabel: true }

export default ConversionNamesField
import { QueryClient } from 'react-query';

const cacheQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    },
});

export default cacheQueryClient
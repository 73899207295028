let date = new Date();

// First day of two months before
let twoMonthsBefore = new Date(date.getFullYear(), date.getMonth() - 2, 1);
export const beginTwoMonthsBefore = twoMonthsBefore.toISOString().slice(0, 10);

// Last day of two months before
let lastDayTwoMonthsBefore = new Date(date.getFullYear(), date.getMonth() - 2 + 1, 0);
export const endTwoMonthsBefore = lastDayTwoMonthsBefore.toISOString().slice(0, 10);

// First day of last month
let lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
export const  beginMonthBefore = lastMonth.toISOString().slice(0, 10);

// Last day of last month
let lastDayLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);
export const endMonthBefore = lastDayLastMonth.toISOString().slice(0, 10);


// Week dates
function formatDateWithZeroes(date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  let GetFirstDayOfLastWeekDate = new Date();
  GetFirstDayOfLastWeekDate.setDate(GetFirstDayOfLastWeekDate.getDate() - GetFirstDayOfLastWeekDate.getDay() - 7);
  export const GetFirstDayOfLastWeek = formatDateWithZeroes(GetFirstDayOfLastWeekDate);
  
  let GetLastDayOfLastWeekDate = new Date(GetFirstDayOfLastWeekDate);
  GetLastDayOfLastWeekDate.setDate(GetLastDayOfLastWeekDate.getDate() - GetLastDayOfLastWeekDate.getDay() + 6);
  export const GetLastDayOfLastWeek = formatDateWithZeroes(GetLastDayOfLastWeekDate);
  
  let GetFirstDayOfTwoWeeksAgoDate = new Date();
  GetFirstDayOfTwoWeeksAgoDate.setDate(GetFirstDayOfTwoWeeksAgoDate.getDate() - GetFirstDayOfTwoWeeksAgoDate.getDay() - 14);
  export const GetFirstDayOfTwoWeeksAgo = formatDateWithZeroes(GetFirstDayOfTwoWeeksAgoDate);
  
  let GetLastDayOfTwoWeeksAgoDate = new Date(GetFirstDayOfTwoWeeksAgoDate);
  GetLastDayOfTwoWeeksAgoDate.setDate(GetLastDayOfTwoWeeksAgoDate.getDate() - GetLastDayOfTwoWeeksAgoDate.getDay() + 6);
  export const GetLastDayOfTwoWeeksAgo = formatDateWithZeroes(GetLastDayOfTwoWeeksAgoDate);
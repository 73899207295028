import * as React from "react";
import {
    Create, SimpleForm, DateInput, ReferenceInput, AutocompleteInput, BooleanInput, NumberInput,
    FormDataConsumer, required, useCreate, useRedirect, useNotify
    // number, minValue, maxLength
} from 'react-admin';
import { useCallback } from 'react';
import EditCreateToolbar from "../../toolbars/EditCreateToolbar";
import { EditActions } from "../../toolbars/EditActions"
import PageTitle from '../../other_components/PageTitle.tsx';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import {
    beginTwoMonthsBefore, endTwoMonthsBefore, beginMonthBefore, endMonthBefore
} from '../../other_components/Dates'
import { validateReportDates } from '../validateReportDates'

export const ReportsCreate = () => {
    const redirect = useRedirect();
    const notify = useNotify();

    const [create] = useCreate();
    const save = useCallback(
        async values => {
            try {
                await create(
                    'reports',
                    { data: values },
                    { returnPromise: true }
                );
                notify('ra.notification.created', {
                    type: 'info',
                    messageArgs: { smart_count: 1 },
                });
                redirect('list', 'reports');
            } catch (error) {
                // The shape of the returned validation errors must match the shape of the form
                if (error?.body?.detail) {
                    console.log(error?.body?.detail)
                    return error?.body?.detail;
                }
            }
        },
        [create, notify, redirect]
    );

    return (
        <Create
            actions={<EditActions />}
            title={<PageTitle preText="Editando relatório: " source="payload.title" />}
            redirect="list">
            <SimpleForm variant="standard"
                onSubmit={save} mode="onBlur" reValidateMode="onBlur"
                validate={validateReportDates}
                toolbar={<EditCreateToolbar />}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <Box flex={2} mr='2rem' sx={{ minWidth: "12rem" }}>
                        <ReferenceInput flex={1} source="client_id" reference="clients"
                            filter={{ "active": true }} >
                            <AutocompleteInput optionText="client_name" validate={required()} />
                        </ReferenceInput>
                    </Box>
                    <Tooltip title="Apenas caso seja update de relatório existente no site" arrow>
                        <Box flex={1} sx={{ minWidth: "3rem" }}>
                            <NumberInput source="report_wp_id" label="ID no WP"
                                helperText="ra.helperText.wp_id" />
                        </Box>
                    </Tooltip>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_start" defaultValue={beginMonthBefore} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }}>
                        <DateInput source="current_period_end" defaultValue={endMonthBefore} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_start" defaultValue={beginTwoMonthsBefore} validate={required()} />
                    </Box>
                    <Box flex={1} mr='2rem' sx={{ minWidth: "5rem" }} >
                        <DateInput source="previous_period_end" defaultValue={endTwoMonthsBefore} validate={required()} />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <Box flex={1} mr='2rem'>
                        <ReferenceInput flex={1} source="report_model_id" reference="elementor_templates">
                            <AutocompleteInput optionText="name" validate={required()}
                                helperText="ra.helperText.template_text" />
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} mr='2rem'>
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput flex={1} source="text_resume_template_id" reference="text_templates"
                                    filter={{ "client_id": formData.client_id, "report_type": "Resumo" }}
                                >
                                    <AutocompleteInput optionText="name" validate={required()}
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box flex={1} mr='2rem'>
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput flex={1} source="text_gads_template_id" reference="text_templates"
                                    filter={{ "client_id": formData.client_id, "report_type": "Google Ads" }}
                                >
                                    <AutocompleteInput optionText="name"
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box flex={1} mr='2rem'>
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput flex={1} source="text_fbads_template_id" reference="text_templates"
                                    filter={{ "client_id": formData.client_id, "report_type": "Facebook Ads" }}
                                >
                                    <AutocompleteInput optionText="name"
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box flex={1} mr='2rem'>
                        <FormDataConsumer>
                            {({ formData }) =>
                                <ReferenceInput flex={1} source="text_other_template_id" reference="text_templates"
                                    filter={{ "client_id": formData.client_id, "report_type": "Outros" }}
                                >
                                    <AutocompleteInput optionText="name"
                                        helperText="ra.helperText.template_text" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    </Box>
                    <Box flex={1} mr='2rem'>
                        <ReferenceInput flex={1} source="text_metrics_template_id" reference="text_templates"
                            filter={{
                                // "client_id": formData.client_id, 
                                "report_type": "Métricas"
                            }}
                        >
                            <AutocompleteInput optionText="name" isRequired
                                defaultValue={"62c886441510fac80f3db55e"}
                                helperText="ra.helperText.template_text" />
                        </ReferenceInput>
                    </Box>
                </Box>
                <Divider flexItem />
                <BooleanInput source="only_test" isRequired fullWidth />
                <Divider flexItem />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                }} >
                    <BooleanInput source="include_fb_custom_conversions_in_total" defaultValue={true} isRequired />
                    <BooleanInput source="include_pinads_in_total" defaultValue={false} isRequired />
                    <BooleanInput source="include_tkads_in_total" defaultValue={false} isRequired />
                </Box>
                <Divider flexItem />
                <Typography variant='h6' >
                    Agendamento de envio
                </Typography>
                <DateInput source="send_report_date" defaultValue={null} />
                <Box mt="3rem"></Box>
            </SimpleForm >
        </Create>
    )
}

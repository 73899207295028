// import * as React from "react";
import {
    SimpleForm, TextInput, ReferenceInput, AutocompleteInput, Create, SelectInput
} from 'react-admin';
import { Box, Divider } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import text_type_choices from './text_type_choices'

// const validateMinNumbers = [number(), minValue(0)]
// const validateLength = [maxLength(12)]

export const TextTemplatesCreate = () => (
    <Create>
        <SimpleForm>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: { xs: '100%', sm: '80%', lg: '60%', xl: '50%' }
            }} >
                <Box flex={2} mr='2rem' sx={{ minWidth: "8rem" }}>
                    <ReferenceInput flex={1} source="client.id" reference="clients">
                        <AutocompleteInput optionText="client_name" />
                    </ReferenceInput>
                </Box>
                <Box flex={2} mr='2rem' sx={{ minWidth: "8rem" }}>
                    <TextInput source="name" fullWidth />
                </Box>
                <Box flex={1} mr='2rem' sx={{ minWidth: "7rem" }}>
                    <SelectInput source="report_type" choices={text_type_choices} fullWidth />
                </Box>
            </Box>
            <Divider flexItem />
            <Box
             sx={{
                wordBreak: "break-word",
                width: "100%",
            }}>
            <RichTextInput source="content" isRequired fullWidth />
            </Box>
        </SimpleForm >
    </Create >
);


import {
    TopToolbar, CreateButton, ExportButton, FilterButton, SortButton
} from 'react-admin';
// import { ImportButton } from "react-admin-import-csv";
// import importConfig from "../data/ImportConfig";


export const ClientListActions = () => (
    <TopToolbar>
        <FilterButton />
        <SortButton fields={['client_name', 'wp_id', 'wp_slug',
            'ga_metrics_type']} />        
        <CreateButton />
        <ExportButton />
        {/* <ImportButton {...props} {...importConfig} /> */}
    </TopToolbar>
);

import { Datagrid, List, TextField, ReferenceField, SimpleList, EditButton, ShowButton, CloneButton } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ListPagination } from '../../other_components/ListPagination';

export const TextTemplatesList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
        // actions={<ClientListActions/>}
        // filters={Filters} filterDefaultValues={{ active: true }}
        sort={{ field: 'updated_at', order: 'DESC' }}
        pagination={<ListPagination />} perPage={20}
        >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => record.name}
                // secondaryText={record => `id no WP: ${record.wp_id}`}
                // tertiaryText={record => `Tipo: ${record.ga_metrics_type}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <ReferenceField source="client.id" reference="clients"><TextField source="client_name" /></ReferenceField>
                    <TextField source="name" />
                    <TextField source="report_type" />
                    <EditButton label="" />
                    <ShowButton label="" />
                    <CloneButton label="" />
                </Datagrid>
            )
            }
        </List>
    );
}

export default TextTemplatesList;

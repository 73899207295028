import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { darkTheme, } from '../layout/CustomTheme';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
    useRedirect,
} from 'react-admin';

import { apiUrl } from './MyDataProvider'

import Box from '@mui/material/Box';




const MyLoginPage = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const redirect = useRedirect();
    const checkAuth = useCheckAuth();
    const navigate = useNavigate();

    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                navigate('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, navigate]);


    const google_auth_url = async () => {

        try {
            const response = await fetch(`${apiUrl}/auth/google/authorize`)
            let url = response.json()
            return Promise.resolve(url).then((value) => value.authorization_url)
        }
        catch (error) {
            return Promise.reject();
        }
    }

    const handleClick = async () => {
        let my_url = await google_auth_url()
        localStorage.setItem('authenticated', 'true');
        redirect(my_url)
    }

    const theme = darkTheme

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    },
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background:
                        'url(https://source.unsplash.com/all/1600x900)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                            <LockIcon />
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        diegogaona.com
                    </Box>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                source="username"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                autoComplete='username'
                            />
                        </Box>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                                autoComplete='current-password'
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ bgcolor: theme.palette.primary.main }}
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </Card>

                <Card sx={{ minWidth: 300, marginTop: '1em' }}>
                <Box
                        sx={{
                            margin: '1em',
                            // display: 'flex',
                            // justifyContent: 'center',
                        }}
                    >
                <CardActions sx={{ padding: '0 0em 0em 0em' }}>
                        <Button
                            variant="contained"
                            type="button"
                            sx={{ bgcolor: theme.palette.primary.main }}
                            disabled={loading}
                            onClick={handleClick}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('ra.auth.google_login')}
                        </Button>
                    </CardActions>
                    </Box>
                </Card>
            </Box>
        </Form>
    );
};

MyLoginPage.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default MyLoginPage;

interface FormValues {
    username?: string;
    password?: string;
}
import { ReportsList } from "./ReportsList"
import { ReportsCreate } from "./ReportsCreate"
import { ReportsEdit } from "./ReportsEdit"
import { ReportsShow } from "./ReportsShow"


const Reports = {
    list: ReportsList,
    create: ReportsCreate,
    edit: ReportsEdit,
    show: ReportsShow
};

export default Reports
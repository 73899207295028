import {
    TextField, Show, DateField, TabbedShowLayout, Tab, Labeled, NumberField,
    RichTextField, FunctionField, BooleanField, useRecordContext
} from 'react-admin';
import UrlFieldEllipsis from '../../custom_fields/UrlFieldEllipsis';
import { CellBox } from '../../custom_fields/CellBox';
import PageTitle from '../../other_components/PageTitle.tsx';
import { ShowActions } from "../../toolbars/ShowActions"

import { Box, Typography, Divider } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import ArticleIcon from '@mui/icons-material/Article';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import DataObjectIcon from '@mui/icons-material/DataObject';

import { SendReportButton } from "./SendReportButton"


export const ReportsShow = () => {

    const ConditionalScheduleFields = () => {
        const record = useRecordContext();
        return (
            record && (record?.send_report_date || record?.report_sent) ?
                <>
                    <Divider flexItem />
                    <Typography variant='h6' sx={{ paddingBottom: '1rem' }} >
                        Agendamento de envio
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '2rem',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' }
                    }} >
                        <Labeled><DateField source="send_report_date" /></Labeled>
                        <Labeled><BooleanField source="report_sent" defaultValue={false} /></Labeled>
                    </Box>
                </> : null
        )
    }

    return (

        <Show title={<PageTitle preText="Relatório: " source="payload.titulo" />}
            actions={<ShowActions />} >
            <TabbedShowLayout >
                <Tab label="Principal">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' }
                    }} >
                        <Box mr='2rem'>
                            <Labeled><TextField source="payload.title" /></Labeled>
                        </Box>
                        <Box mr='2rem'>
                            <Labeled><DateField source="created_at" locales="pt-BR" /></Labeled>
                        </Box>
                        <Box mr='2rem'>
                            <Labeled><DateField source="updated_at" locales="pt-BR" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><TextField source="id" /></Labeled>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' }
                    }} >
                        <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                            <Box mb='1rem'>
                                <Labeled><DateField source="current_period_start" locales="pt-BR" /></Labeled>
                            </Box>
                            <Box>
                                <Labeled><DateField source="current_period_end" locales="pt-BR" /></Labeled>
                            </Box>
                        </Box>
                        <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                            <Box mb='1rem'>
                                <Labeled><DateField source="previous_period_start" locales="pt-BR" /></Labeled>
                            </Box>
                            <Box>
                                <Labeled><DateField source="previous_period_end" locales="pt-BR" /></Labeled>
                            </Box>
                        </Box>
                        <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                            <Box mb='1rem'>
                                <Labeled><TextField source="report_wp_id" /></Labeled>
                            </Box>
                            <Box>
                                <Labeled><UrlFieldEllipsis source="report_wp_url" target='_blank' /></Labeled>
                            </Box>
                        </Box>
                        <Box flex={1} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                            <Box mb='1rem'>
                                <Labeled><TextField source="payload.cliente" /></Labeled>
                            </Box>
                            <Box>
                                <Labeled><TextField source="payload.status" /></Labeled>
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={3} sx={{ display: "flex", flexDirection: 'column', marginRight: '1rem' }}>
                        <Box mb='1rem'>
                            <Labeled><TextField source="payload.titulo" /></Labeled>
                        </Box>
                        <Box>
                            <Labeled><TextField source="payload.subtitulo" /></Labeled>
                        </Box>
                        <Box mb='1rem'>
                            <Labeled><TextField source="payload.shortcode_outros" /></Labeled>
                        </Box>
                        <ConditionalScheduleFields />
                    </Box>
                </Tab>

                <Tab label="Dados">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    }} >
                        <CellBox>
                            <Labeled><TextField source="payload.valor_total_impressoes" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.valor_total_interacoes" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.valor_total_conversoes" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.valor_total_investimento" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.perc_total_google_ads" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.perc_total_facebook_ads" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.perc_mobile_google_ads" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.perc_busca_google_ads" /></Labeled>
                        </CellBox>
                    </Box>
                    <Typography variant='h6' >
                        Dados para os gráficos
                    </Typography>
                    <Typography variant='p' >
                        Google Ads
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    }} >
                        {/* First 4 fields with data comma separated */}
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled label="Atual - Interações"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_atual_chart_google_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_atual_chart_google_1.split(',')[0])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled label="Anterior - Interações"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_anterior_chart_google_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_anterior_chart_google_1.split(',')[0])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled label="Atual - Investimento"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_atual_chart_google_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_atual_chart_google_1.split(',')[1])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled label="Anterior - Investimento"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_anterior_chart_google_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_anterior_chart_google_1.split(',')[1])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><NumberField source="payload.valor_atual_chart_google_2" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><NumberField source="payload.valor_anterior_chart_google_2" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><NumberField source="payload.valor_atual_chart_google_3" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><NumberField source="payload.valor_anterior_chart_google_3" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                    </Box>
                    <Typography variant='p' >
                        Facebook Ads
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    }} >
                        <CellBox>
                            <Labeled label="Atual - Interações"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_atual_chart_facebook_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_atual_chart_facebook_1.split(',')[0])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox>
                            <Labeled label="Anterior - Interações"><FunctionField
                                render={
                                    record => {
                                        return record?.payload?.valor_anterior_chart_facebook_1 ? Intl.NumberFormat("pt-BR")
                                            .format(parseInt(record?.payload?.valor_anterior_chart_facebook_1.split(',')[0])) : "";
                                    }}
                            /></Labeled>
                        </CellBox >
                        <CellBox>
                            <Labeled label="Atual - Investimento"><FunctionField
                                render={
                                    record => {
                                        return record?.payload?.valor_atual_chart_facebook_1 ? Intl.NumberFormat("pt-BR")
                                            .format(parseInt(record?.payload?.valor_atual_chart_facebook_1.split(',')[1])) : "";
                                    }}
                            /></Labeled>
                        </CellBox >
                        <CellBox>
                            <Labeled label="Anterior - Investimento"><FunctionField
                                render={record => {
                                    return record?.payload?.valor_anterior_chart_facebook_1 ? Intl.NumberFormat("pt-BR")
                                        .format(parseInt(record?.payload?.valor_anterior_chart_facebook_1.split(',')[1])) : "";
                                }}
                            /></Labeled>
                        </CellBox >
                        <CellBox>
                            <Labeled><NumberField source="payload.valor_atual_chart_facebook_2" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><NumberField source="payload.valor_anterior_chart_facebook_2" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><NumberField source="payload.valor_atual_chart_facebook_3" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><NumberField source="payload.valor_anterior_chart_facebook_3" locales="pt-BR"
                                options={{ maximumFractionDigits: 0 }} /></Labeled>
                        </CellBox>
                    </Box>
                    <Typography variant='h6' >
                        Dados para a tabela
                    </Typography>
                    <Typography variant='p' >
                        Google Ads
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    }} >
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula1" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula2" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula3" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula4" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula5" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula6" /></Labeled>
                        </CellBox>
                        <CellBox sx={{ backgroundColor: "#f0ba27" }}>
                            <Labeled><TextField source="payload.celula7" /></Labeled>
                        </CellBox>
                    </Box>
                    <Typography variant='p' >
                        Facebook Ads
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '100%', xl: '80%' },
                    }} >
                        <CellBox>
                            <Labeled><TextField source="payload.celula8" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula9" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula10" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula11" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula12" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula13" /></Labeled>
                        </CellBox>
                        <CellBox>
                            <Labeled><TextField source="payload.celula14" /></Labeled>
                        </CellBox>
                    </Box>

                    <Typography variant='h6' >
                        Textos comparativos para os cards
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start', // 'space-between',
                        // flexWrap: 'wrap',
                        width: { xs: '100%', sm: '100%', lg: '80%', xl: '70%' },
                    }} >

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start', // 'space-between',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '80%' },
                        }} >
                            <CellBox sx={{ maxWidth: { lg: '80%', xl: '70%' }, }}>
                                <Labeled><TextField source="payload.tooltip_card_1" /></Labeled>
                            </CellBox>
                            <CellBox sx={{ maxWidth: { lg: '80%', xl: '70%' }, }}>
                                <Labeled><TextField source="payload.tooltip_card_2" /></Labeled>
                            </CellBox>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start', // 'space-between',
                            flexWrap: 'wrap',
                            width: { xs: '100%', sm: '100%', lg: '80%', xl: '80%' },
                        }} >
                            <CellBox sx={{ maxWidth: { lg: '80%', xl: '70%' }, }}>
                                <Labeled><TextField source="payload.tooltip_card_3" /></Labeled>
                            </CellBox>
                            <CellBox sx={{ maxWidth: { lg: '80%', xl: '70%' }, }}>
                                <Labeled><TextField source="payload.tooltip_card_4" /></Labeled>
                            </CellBox>
                        </Box>
                    </Box>


                </Tab>
                <Tab label='Textos'>
                    <TabbedShowLayout syncWithLocation={false} >
                        {/* FOR MOBILE */}
                        <Tab label="" icon={<ArticleIcon />} sx={{ minWidth: '4rem', display: { xs: 'flex', sm: 'none' } }} >
                            <RichTextField source="payload.texto_resumo" label={false} />
                        </Tab>

                        <Tab label="" icon={<GoogleIcon />} sx={{ minWidth: '4rem', display: { xs: 'flex', sm: 'none' } }} >
                            <RichTextField source="payload.texto_google_ads" label={false} />
                        </Tab>

                        <Tab label="" icon={<FacebookIcon />} sx={{ minWidth: '4rem', display: { xs: 'flex', sm: 'none' } }} >
                            <RichTextField source="payload.texto_facebook_ads" label={false} />
                        </Tab>

                        <Tab label="" icon={< ViewCarouselIcon />} sx={{ minWidth: '4rem', display: { xs: 'flex', sm: 'none' } }} >
                            <RichTextField source="payload.text_other" label={false} />
                        </Tab>

                        <Tab label="" icon={< DataObjectIcon />} sx={{ minWidth: '4rem', display: { xs: 'flex', sm: 'none' } }} >
                            <RichTextField source="text_metrics_template" label={false} />
                        </Tab>

                        {/* FOR OTHER SIZES */}
                        <Tab icon={<ArticleIcon />} label="Resumo" sx={{ display: { xs: 'none', sm: 'flex' } }} >
                            <RichTextField source="payload.texto_resumo" label={false} />
                        </Tab>

                        <Tab icon={<GoogleIcon />} label="Google Ads" sx={{ display: { xs: 'none', sm: 'flex' } }} >
                            <RichTextField source="payload.texto_google_ads" label={false} />
                        </Tab>

                        <Tab icon={<FacebookIcon />} label="Meta Ads" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <RichTextField source="payload.texto_facebook_ads" label={false} />
                        </Tab>

                        <Tab icon={< ViewCarouselIcon />} label="Outros Ads" sx={{ display: { xs: 'none', sm: 'flex' } }} >
                            <RichTextField source="payload.text_other" label={false} />
                        </Tab>

                        <Tab icon={< DataObjectIcon />} label="Métricas" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <RichTextField source="text_metrics_template" label={false} />
                        </Tab>


                    </TabbedShowLayout>
                </Tab>

            </TabbedShowLayout>
            <Box sx={{ paddingTop: "1rem" }}>
                <SendReportButton />
            </Box>
        </Show >
    )
}
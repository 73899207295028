import React from 'react';
import { useNotify, useRecordContext, useDataProvider } from 'react-admin';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { useMutation } from 'react-query';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const SendReportWhatsAppButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const notify = useNotify();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const phones = record?.client_config?.phones
    const { mutate, isLoading } = useMutation(
        ['send_messages/send_whats_image'],
        () => dataProvider.sendSimplified(`send_messages/send_whats_image`, {            
            data: {
                "to": phones ?? null,
                "message": record.text_resume,          
                "whatsapp_upload_id": record.whatsapp_upload_id,
            }
        }),        
        { onSuccess: () => notify('ra.notification.send_simplified', { type: 'success' }) },
        {
            onFailure: (error) => notify(`Erro no envio: ${error.message}`, { type: 'error' }),
        }
    );

    return (
        <>
            {isSmall ? (
                <Button
                    label="Enviar WhatsApp"
                    children="WhatsApp"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="outlined"
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                />
            ) : (
                <Button
                    label="Enviar WhatsApp"
                    children="Enviar WhatsApp"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="outlined"
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                />
            )}
        </>
    )
}
import React, { useState } from 'react';
import {
    required,
    Button,
    SaveButton,
    ReferenceInput,
    AutocompleteInput,
    useNotify,
    Form,
    useGetRecordId,
    CreateBase,
    DateInput,
    BooleanInput,
    FormDataConsumer,
    useRecordContext
} from 'react-admin';
import { Box, Tooltip } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
    GetFirstDayOfLastWeek, GetLastDayOfLastWeek, GetFirstDayOfTwoWeeksAgo, GetLastDayOfTwoWeeksAgo
} from '../../other_components/Dates'



function SimplifiedReportDialog() {
    const [showDialog, setShowDialog] = useState(false);

    const recordId = useGetRecordId()
    const record = useRecordContext()
    const title = `Relatório Simplificado para ${record.client_name}`

    const postDefaultValue = () => ({ client_id: recordId });

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const notify = useNotify();

    const SendButton = props => {

        return <SaveButton type="button" {...props} label="ra.action.simplified_report"
            icon={<WhatsAppIcon />}
            mutationOptions={{
                onSuccess: () => {
                    notify('ra.notification.send_simplified', { type: 'success' });
                    handleCloseClick()
                },
                onFailure: (error) => { notify(`Erro no envio: ${error.message}`, { type: 'error' }) }
            }} />;
    };

    return (
        <>
            <Box sx={{
                maxWidth: '16rem',
                marginTop: '3rem',
                marginBottom: '1rem',
            }}>
                <Button onClick={handleClick} label="ra.action.simplified_report"
                    variant="contained"
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                    sx={{
                        minHeight: '2.5rem',
                    }}
                />
            </Box>

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="ra.action.send_simplified"
            >
                <DialogTitle>{title}</DialogTitle>
                <CreateBase resource='reports_simplified'>
                    <Form defaultValues={postDefaultValue}
                    >
                        <DialogContent>
                            <ReferenceInput flex={1} source="client_id" reference="clients">
                                <AutocompleteInput label="Cliente" optionText="client_name" validate={required()}
                                    sx={{ display: "none" }} />
                            </ReferenceInput>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                            }} >
                                <DateInput source="current_period_start" defaultValue={GetFirstDayOfLastWeek} validate={required()} />
                                <DateInput source="current_period_end" defaultValue={GetLastDayOfLastWeek} validate={required()} />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                            }} >
                                <DateInput source="previous_period_start" defaultValue={GetFirstDayOfTwoWeeksAgo} validate={required()} />
                                <DateInput source="previous_period_end" defaultValue={GetLastDayOfTwoWeeksAgo} validate={required()} />
                            </Box>
                            <FormDataConsumer>
                                {({ formData }) =>
                                    <ReferenceInput flex={1}
                                        source="text_resume_template_id" reference="text_templates"
                                        filter={{ "client_id": formData.client_id, "report_type": "Simplificado" }}
                                    >
                                        <AutocompleteInput label="Template de texto" optionText="name"
                                            validate={required()} fullWidth
                                            helperText="ra.helperText.template_text" />
                                    </ReferenceInput>
                                }
                            </FormDataConsumer>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                            }} >
                                <Tooltip title="Incluir as conversões personalizadas da Meta nos totais" arrow>
                                    <Box>
                                        <BooleanInput source="include_fb_custom_conversions_in_total" defaultValue={true} />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Incluir Pinterest Ads nos totais" arrow>
                                    <Box>
                                        <BooleanInput source="include_pinads_in_total" defaultValue={true} />
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Incluir TikTok Ads nos totais" arrow>
                                    <Box>
                                        <BooleanInput source="include_tkads_in_total" defaultValue={true} />
                                    </Box>
                                </Tooltip>
                                {/* sx={{ display: "none" }} */}
                                <BooleanInput source="send_to_whatsapp" defaultValue={true} />
                                <BooleanInput source="send_to_email" defaultValue={false} />
                            </Box>
                        </DialogContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap',
                        }} >
                            <DialogActions>
                                <Button
                                    sx={{ marginRight: "3rem" }}
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                >
                                    <IconCancel />
                                </Button>
                                <SendButton />
                            </DialogActions>
                        </Box>
                    </Form>
                </CreateBase>
            </Dialog>
        </>
    );
}

export default SimplifiedReportDialog;

import { styled } from '@mui/system';
import { UrlField } from 'react-admin';

const UrlFieldEllipsis = styled(UrlField)({
    display: "block",
    width: "20rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    '&:hover, &:focus, &:active': {
        whiteSpace: "normal",
        overflow: "visible",
        textOverflow: "clip",
        wordBreak: "break-all"
    },
    });

export default UrlFieldEllipsis
